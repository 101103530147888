import DoneIcon from "@mui/icons-material/Done";
import ShareIcon from "@mui/icons-material/Share";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import { useState } from "react";
import axios from "axios";
import HeaderTop from "../../../layouts/frontend/HeaderTop";
import NavBar from "../../../layouts/frontend/NavBar";

export default function Register() {
    const [registerInput, setRegister] = useState({
        firstname: "",
        lastname: "",
        companyname: "",
        username: "",
        password: "",
        email: "",
        phone: "",
        address: "",
        address2: "",
        city: "",
        codepostal: "",
        error_list: [],
    });
    const [isGIGAChecked, setIsGIGAChecked] = useState(false);
    const [isEDPAChecked, setIsEDPAChecked] = useState(false);
    const [isASIChecked, setIsASIChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        switch (event.target.name) {
            case "GIGA":
                setIsGIGAChecked(event.target.checked);
                break;
            case "EDPA":
                setIsEDPAChecked(event.target.checked);
                break;
            case "ASI":
                setIsASIChecked(event.target.checked);
                break;
            default:
                break;
        }
    };

    const [isRegistered, setIsRegistered] = useState(false );

    const handleInput = (e) => {
        e.persist();
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    };
    const registerSubmit = (e) => {
        e.preventDefault();

        const data = {
            firstname: registerInput.firstname,
            lastname: registerInput.lastname,
            companyname: registerInput.companyname,
            username: registerInput.username,
            password: registerInput.password,
            email: registerInput.email,
            phone: registerInput.phone,
            address: registerInput.address,
            address2: registerInput.address2,
            city: registerInput.city,
            codepostal: registerInput.codepostal,
            affiliations: {
                GIGA: isGIGAChecked,
                EDPA: isEDPAChecked,
                ASI: isASIChecked,
            },
        };
        axios.get("/sanctum/csrf-cookie").then((response) => {
            axios.post(`/api/register`, data).then((res) => {
                console.log(response);
                if (res.data.status === 200) {
                    localStorage.setItem("auth_token", res.data.token);
                    localStorage.setItem("auth_username", res.data.username);
                    setIsRegistered(true);
                } else {
                    setRegister({
                        ...registerInput,
                        error_list: res.data.validation_errors,
                    });
                }
            });
        });
    };

    return (
        <>
            <main>
            <section
                className="d-flex firstSection justify-content-center align-items-center"
                style={{ height: "auto" }}
            >
                <article style={{ width: "40vw", paddingLeft: "0" }}>
                    <div
                        style={{
                            backgroundColor: "#F7F7F7",
                            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                            padding: "2vh 0",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#000000",
                                color: "#FFFFFF",
                                textAlign: "center",
                                padding: "1vh 0",
                                marginBottom: "4vh",
                            }}
                        >
                            <h3>REGISTER NOW</h3>
                        </div>
                        {isRegistered ? (
                            <div
                                className="registration-success d-flex"
                                style={{
                                    borderColor: "#daedda",
                                    margin: "2vh 3vw",
                                    padding: "10px",
                                    backgroundColor: "#daedda",
                                    color: "##2b622b",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: "11px",
                                        textAlign: "center",
                                        marginTop: "15px",
                                    }}
                                >
                                    Thanks for contacting us! We will get in
                                    touch with you shortly.
                                </p>
                            </div>
                        ) : (
                            <form
                                style={{ padding: "20px" }}
                                onSubmit={registerSubmit}
                            >
                                <div className="mb-3 d-flex">
                                    <input
                                        type="text"
                                        onChange={handleInput}
                                        value={registerInput.firstname}
                                        className="form-control me-2 py-3"
                                        aria-describedby="emailHelp"
                                        name="firstname"
                                        placeholder="First Name"
                                    />
                                    <span>
                                        {registerInput.error_list.firstname}
                                    </span>
                                    <input
                                        type="text"
                                        onChange={handleInput}
                                        value={registerInput.lastname}
                                        className="form-control py-3"
                                        aria-describedby="emailHelp"
                                        placeholder="lastname"
                                        name="lastname"
                                    />
                                    <span>
                                        {registerInput.error_list.lastname}
                                    </span>
                                </div>
                                <div className="mb-3 d-flex">
                                    <input
                                        type="text"
                                        onChange={handleInput}
                                        value={registerInput.companyname}
                                        className="form-control py-3"
                                        aria-describedby="emailHelp"
                                        placeholder="Company Name"
                                        name="companyname"
                                    />
                                    <span>
                                        {registerInput.error_list.companyname}
                                    </span>
                                </div>

                                <div className="mb-3 d-flex">
                                    <input
                                        type="text"
                                        onChange={handleInput}
                                        value={registerInput.username}
                                        className="form-control py-3"
                                        aria-describedby="emailHelp"
                                        placeholder="Username"
                                        name="username"
                                    />
                                    <span>
                                        {registerInput.error_list.username}
                                    </span>
                                </div>
                                <div className="mb-4 pb-4 d-flex">
                                    <input
                                        type="password"
                                        onChange={handleInput}
                                        value={registerInput.password}
                                        className="form-control me-2 py-3"
                                        placeholder="Enter Password"
                                        name="password"
                                    />
                                    <span>
                                        {registerInput.error_list.password}
                                    </span>
                                    <input
                                        type="password"
                                        className="form-control py-3"
                                        id="exampleInputPassword1"
                                        placeholder="Confirm Password"
                                    />
                                    <span>
                                        {registerInput.error_list.password}
                                    </span>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div>
                                        <input
                                            type="email"
                                            onChange={handleInput}
                                            value={registerInput.email}
                                            className="form-control py-3"
                                            aria-describedby="emailHelp"
                                            style={{ width: "18vw" }}
                                            name="email"
                                        />
                                        <span style={{ fontSize: "12px" }}>
                                            Enter Email
                                        </span>
                                        <span>
                                            {registerInput.error_list.email}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            type="email"
                                            className="form-control py-3"
                                            aria-describedby="emailHelp"
                                            style={{ width: "18vw" }}
                                        />
                                        <span style={{ fontSize: "12px" }}>
                                            Confirm Email
                                        </span>
                                        <span>
                                            {registerInput.error_list.email}
                                        </span>
                                    </div>
                                </div>

                                <div className="mb-3 d-flex">
                                    <input
                                        type="text"
                                        onChange={handleInput}
                                        value={registerInput.phone}
                                        className="form-control py-3"
                                        aria-describedby="emailHelp"
                                        placeholder="Phone number"
                                        name="phone"
                                    />
                                    <span>
                                        {registerInput.error_list.phone}
                                    </span>
                                </div>
                                <div>
                                    <label htmlFor="">
                                        <b>Address</b>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleInput}
                                        value={registerInput.address}
                                        className="form-control py-3"
                                        aria-describedby="emailHelp"
                                        name="address"
                                    />
                                    <span style={{ fontSize: "12px" }}>
                                        Street Address
                                    </span>
                                    <span>
                                        {registerInput.error_list.address}
                                    </span>
                                </div>
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        onChange={handleInput}
                                        value={registerInput.address2}
                                        className="form-control py-3"
                                        aria-describedby="emailHelp"
                                        name="address2"
                                    />
                                    <span style={{ fontSize: "12px" }}>
                                        Address Line 2
                                    </span>
                                    <span>
                                        {registerInput.error_list.address2}
                                    </span>
                                </div>
                                <div className="mb-3 mt-4 d-flex">
                                    <div className="me-2">
                                        <input
                                            type="text"
                                            onChange={handleInput}
                                            value={registerInput.city}
                                            className="form-control py-3"
                                            aria-describedby="emailHelp"
                                            style={{ width: "18vw" }}
                                            name="city"
                                        />
                                        <span style={{ fontSize: "12px" }}>
                                            City
                                        </span>
                                        <span>
                                            {registerInput.error_list.city}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            onChange={handleInput}
                                            value={registerInput.state}
                                            className="form-control py-3"
                                            aria-describedby="emailHelp"
                                            style={{ width: "18vw" }}
                                            name="state"
                                        />
                                        <span style={{ fontSize: "12px" }}>
                                            State / Province / Region
                                        </span>
                                        <span>
                                            {registerInput.error_list.state}
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3 d-flex">
                                    <div className="me-2">
                                        <input
                                            type="text"
                                            onChange={handleInput}
                                            value={registerInput.codepostal}
                                            className="form-control py-3"
                                            aria-describedby="emailHelp"
                                            style={{ width: "18vw" }}
                                            name="codepostal"
                                        />
                                        <span style={{ fontSize: "12px" }}>
                                            ZIP / Postal Code
                                        </span>
                                        <span>
                                            {
                                                registerInput.error_list
                                                    .codepostal
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            onChange={handleInput}
                                            value={registerInput.country}
                                            className="form-control py-3"
                                            aria-describedby="emailHelp"
                                            style={{ width: "18vw" }}
                                            name="country"
                                        />
                                        <span style={{ fontSize: "12px" }}>
                                            Country
                                        </span>
                                        <span>
                                            {registerInput.error_list.Country}
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <h6>Affiliation</h6>
                                    <div>
                                        <div className="mb-3 form-check align-self-end">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="GIGACheck"
                                                name="GIGA"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="GIGACheck"
                                                style={{
                                                    fontWeight: "500",
                                                    color: "#000000",
                                                }}
                                            >
                                                GIGA
                                            </label>
                                        </div>
                                        <div className="mb-3 form-check align-self-end">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="EDPACheck"
                                                name="EDPA"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="EDPACheck"
                                                style={{
                                                    fontWeight: "500",
                                                    color: "#000000",
                                                }}
                                            >
                                                EDPA
                                            </label>
                                        </div>
                                        <div className="mb-3 form-check align-self-end">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="ASICheck"
                                                name="ASI"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="ASICheck"
                                                style={{
                                                    fontWeight: "500",
                                                    color: "#000000",
                                                }}
                                                name="ASI"
                                            >
                                                ASI
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <span>
                                    Are you a member of any of the following
                                    groups?
                                </span>

                                <div className="d-flex align-items-center mt-4">
                                    <button
                                        className="btnSecondSection"
                                        style={{
                                            backgroundColor: "#000000",
                                            padding: "8px",
                                            color: "white",
                                        }}
                                        type="submit"
                                    >
                                        CREATE ACCOUNT
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </article>
                <aside style={{ width: "50vw" }}>
                    <h4>BECOME A GLOW MOBILE BOX MEMBER</h4>
                    <div className="d-flex pt-4">
                        <DoneIcon
                            sx={{
                                color: "gold",
                                fontSize: 30,
                            }}
                        />
                        <p
                            style={{
                                paddingLeft: "3px",
                                fontSize: "18px",
                            }}
                        >
                            Order new prints for your Glow Mobile Box
                        </p>
                    </div>
                    <div className="d-flex">
                        <DoneIcon
                            sx={{
                                color: "gold",
                                fontSize: 30,
                                fontWeight: "bold",
                            }}
                        />
                        <p
                            style={{
                                paddingLeft: "3px",
                                fontSize: "18px",
                            }}
                        >
                            Get access to a variety of spare parts for your Glow
                            Mobile Box
                        </p>
                    </div>
                </aside>
            </section>

            <section
                className="d-flex"
                style={{
                    paddingTop: "5vh",
                    paddingBottom: "5vh",
                    width: "100vw",
                    paddingLeft: "8vw",
                }}
            >
                <div className="pe-3">
                    <ShareIcon />
                </div>
                <p style={{ fontSize: "20px", paddingRight: "10px" }}>
                    Share this post
                </p>
                <div className="d-flex">
                    <div>
                        <FacebookIcon sx={{ color: "#4054B2", fontSize: 30 }} />
                    </div>
                    <div>
                        <XIcon sx={{ color: "#6EC1E4", fontSize: 30 }} />
                    </div>
                    <div>
                        <GoogleIcon sx={{ color: "#cf2e2e", fontSize: 30 }} />
                    </div>
                    <div>
                        <EmailIcon sx={{ color: "#cf2e2e", fontSize: 30 }} />
                    </div>
                </div>
            </section>
            </main>
        </>
    );
}

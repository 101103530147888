export default function Banners() {
  return (
    <>
      <div
        className='accordion'
        id='accordionExample'
        style={{ width: '85vw' }}
      >
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseOne'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              Dirt or Stains
            </button>
          </h2>
          <div
            id='collapseOne'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              We always advise working in a clean environment, though understand
              that things happen. In the case that the banners become stained or
              dirty they can be washed at 30 degrees Celsius without additives.
              However, this will solve the fire retardant B1 coating and affect
              the banners elasticity/colors lightly.
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseTwo'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              Do you keep my artwork on file?
            </button>
          </h2>
          <div
            id='collapseTwo'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              Your artwork is valuable to us, and we take every step to keep a
              safe and seamless print workflow. To eliminate the inconvenience
              of sending recurring artwork. We generally try to store all
              artwork for up to 12 months.
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseThree'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              Handling
            </button>
          </h2>
          <div
            id='collapseThree'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              Always handle fabric in a clean area with clean hands or gloves.
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseFourth'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              Installation
            </button>
          </h2>
          <div
            id='collapseFourth'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              Insert the silicone edge into the frame groove, starting from the
              four corners. Then pull the banner’s edge and insert the remaining
              sides. Gradually insert the silicon edging into the grooves all
              the way around the frame. Ensure that the banner is properly
              aligned and centered within the frame. Adjust the tension by
              gently pulling on the banner and repositioning the silicone edging
              if needed.
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseFive'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              Storage
            </button>
          </h2>
          <div
            id='collapseFive'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              To ensure the quality of your banners, we recommend folding
              loosely and facing inward. Ideally packed back in the same bag
              that they were delivered in. If possible do not stack folder
              banners on top of each other, this will help precent creases or
              ink transfers.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import SearchIcon from '@mui/icons-material/Search'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import Logo from '../../assets/frontend/img/512 LOGO.png'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import axios from 'axios'

function NavBar() {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [cart, setCart] = useState([])
   //  const apiUrl = 'http://127.0.0.1:8000/'
 const apiUrl = 'https://www.api.glowmobilebox.com/'

  useEffect(() => {
    const fetchData = async () => {
      let isMounted = true
      try {
        const res = await axios.get('/api/cart')
        if (isMounted) {
          if (
            res.data &&
            res.data.hasOwnProperty('status') &&
            res.data.hasOwnProperty('cart')
          ) {
            if (res.data.status === 200) {
              setCart(res.data.cart)
            } else if (res.data.status === 401) {
              //
            } else {
              console.error('Invalid response format:', res)
            }
          } else {
            console.error('Invalid response format:', res)
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        isMounted && (isMounted = false)
      }
    }

    fetchData()
  }, [])

  const determinePrice = (product) => {
    return product?.originalPrice ?? 0
  }

  const subtotal = cart.reduce((acc, item) => {
    const price = determinePrice(item.product)
    const quantity = parseInt(item.product_qty, 10) || 0
    console.log(`Price: ${price}, Quantity: ${quantity}`) // Check calculated values
    return acc + price * quantity
  }, 0)

  const handleShopClick = (e) => {
    e.preventDefault()
    navigate('/allproducts')
  }
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const [isSticky, setIsSticky] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    setIsSticky(window.scrollY > 0); 
  };

  window.addEventListener("scroll", handleScroll);

  return () => window.removeEventListener("scroll", handleScroll);
}, []);

  var AuthButtons = ''

  if (!localStorage.getItem('auth_token')) {
    AuthButtons = (
      <div>
        <Link
          to='/login'
          className='px-4'
          style={{
            textTransform: 'none',
            textDecoration: 'none',
            color: '#FFFFFF',
            fontFamily: "'Source Sans 3', sans-serif",
            fontSize: '13.6px',
          }}
        >
          LOGIN
        </Link>
      </div>
    )
  } else {
    // Retrieving the role from localStorage
    const userRole = localStorage.getItem('role_as') // Ensure the key matches what you have set on login

    // Determine the dashboard route based on the user role
    const dashboardRoute =
      userRole === '1' ? '/admin/dashboard' : '/dashboard-client'

    AuthButtons = (
      <div>
        <Link
          to={dashboardRoute}
          className='px-4'
          style={{
            textTransform: 'none',
            textDecoration: 'none',
            color: '#FFCB05',
            fontWeight: '500',
          }}
        >
          MY ACCOUNT
        </Link>
      </div>
    )
  }

  return (
    <>
      <div
        className={`d-flex NavBar justify-content-between align-items-center main-content ${
          isSticky ? 'sticky' : ''
        }`}
      >
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ paddingLeft: '3vw' }}
        >
          <Link to='/'>
            <img src={Logo} alt='logo' width='90' />
          </Link>
          <nav className='nav-items'>
            <ul className='nav'>
              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle'
                  href='/allproducts'
                  onClick={handleShopClick}
                  role='button'
                  aria-expanded='false'
                  style={{
                    color: 'white',
                    padding: '40px 10px',
                    marginLeft: '20px',
                    fontFamily: "'Source Sans 3', sans-serif",
                    fontSize: '13.6px',
                  }}
                  data-bs-toggle='dropdown'
                >
                  Shop
                </a>
                <ul className='dropdown-menu'>
                  <li>
                    <Link className='dropdown-item' to='/allproducts'>
                      All Frames
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/new-banners'>
                      New Banners
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/accessories'>
                      Accessories
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/spare-parts'>
                      Spare parts
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/trade-Show'
                  style={{
                    color: 'white',
                    padding: '40px 10px',
                    marginLeft: '20px',
                    fontFamily: "'Source Sans 3', sans-serif",
                    fontSize: '13.6px',
                  }}
                >
                  TRADE SHOW
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/print-instruction'
                  style={{
                    color: 'white',
                    padding: '40px 10px',
                    marginLeft: '20px',
                    fontFamily: "'Source Sans 3', sans-serif",
                    fontSize: '13.6px',
                  }}
                >
                  PRINT INSTRUCTIONS
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/blog-press'
                  style={{
                    color: 'white',
                    padding: '40px 10px',
                    marginLeft: '20px',
                    fontFamily: "'Source Sans 3', sans-serif",
                    fontSize: '13.6px',
                  }}
                >
                  BLOG/PRESS
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/news'
                  style={{
                    color: 'white',
                    padding: '40px 10px',
                    marginLeft: '20px',
                    fontFamily: "'Source Sans 3', sans-serif",
                    fontSize: '13.6px',
                  }}
                >
                  NEWS
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/faq'
                  style={{
                    color: 'white',
                    padding: '40px 10px',
                    marginLeft: '20px',
                    fontFamily: "'Source Sans 3', sans-serif",
                    fontSize: '13.6px',
                  }}
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className='d-flex'>
          <div>
            <div className='navLogin'>
              <SearchIcon sx={{ paddingTop: '5px' }} />
              {AuthButtons}
            </div>
          </div>
          <div className='d-flex pe-3'>
            <div>
              <button
                type='button'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasRight'
                aria-controls='offcanvasRight'
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  border: 'none',
                  paddingRight:'10px',
                }}
              >
                <ShoppingBagIcon sx={{ fontSize: 20 }} />
              </button>
              <div
                className='offcanvas offcanvas-end'
                id='offcanvasRight'
                aria-labelledby='offcanvasRightLabel'
              >
                <div className='offcanvas-header'>
                  <h5 className='offcanvas-title' id='offcanvasRightLabel'>
                    Shopping Cart
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'
                  ></button>
                </div>
                <div>
                  <div
                    className='offcanvas-body d-flex justify-content-between flex-column'
                    style={{ borderBottom: '1px solid #c2bebe' }}
                  >
                    {cart.map((item) => (
                      <div key={item.id} className='d-flex flex-column mt-2'>
                        <div
                          className='d-flex justify-content-between'
                          style={{
                            borderBottom: '1px solid #c2bebe',
                            width: '28vw',
                          }}
                        >
                          {item.product ? ( // Check if product exists
                            <>
                              <div>
                                <h6>{item.product.name}</h6>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    marginTop: '15px',
                                  }}
                                >
                                  {item.product_qty} x ${' '}
                                  {determinePrice(item.product)}
                                </p>
                              </div>
                              <div>
                                <img
                                  style={{ width: '8vw', height: 'auto' }}
                                  src={`${apiUrl}storage/${item.product.image}`}
                                  alt={item.product.name}
                                />
                              </div>
                            </>
                          ) : (
                            <div>Product information is not available.</div> // Fallback content
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className='d-flex justify-content-between px-4 mt-1'>
                    <h5>SUBTOTAL : </h5>
                    <p>${subtotal.toFixed(2)}</p>
                  </div>
                  <br />
                  <div className='d-flex flex-column align-items-center'>
                    <button
                      type='submit'
                      style={{
                        textTransform: 'uppercase',
                        width: '22vw',
                        height: '8vh',
                        border: 'none',
                        letterSpacing: '0.025em',
                        marginBottom: '10px',
                      }}
                      onClick={() => navigate('/cart')}
                    >
                      View Cart
                    </button>
                    <button
                      type='submit'
                      style={{
                        textTransform: 'uppercase',
                        width: '22vw',
                        height: '8vh',
                        border: 'none',
                        color: 'white',
                        backgroundColor: '#181818',
                        letterSpacing: '0.025em',
                      }}
                      onClick={() => navigate('/checkout')}
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='menu-icon ms-2'>
              <MenuIcon style={{ color: 'white' }} onClick={handleMenuClick} />
            </div>
            {isMenuOpen && (
              <ul className='VerticalMenu'>
                <li>
                  <Link to='/allproducts' onClick={() => setIsMenuOpen(false)}>
                    Shop
                  </Link>
                </li>
                <li>
                  <Link to='/trade-Show' onClick={() => setIsMenuOpen(false)}>
                    TRADE SHOW
                  </Link>
                </li>
                <li>
                  <Link
                    to='/print-instruction'
                    onClick={() => setIsMenuOpen(false)}
                  >
                    PRINT INSTRUCTIONS
                  </Link>
                </li>
                <li>
                  <Link to='/blog-press' onClick={() => setIsMenuOpen(false)}>
                    BLOG/PRESS
                  </Link>
                </li>
                <li>
                  <Link to='/news' onClick={() => setIsMenuOpen(false)}>
                    News
                  </Link>
                </li>
                <li>
                  <Link to='/faq' onClick={() => setIsMenuOpen(false)}>
                    FAQ
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default NavBar

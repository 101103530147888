import axios from 'axios';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute() {
  const [Authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const res = await axios.get('/api/checkingAuthenticated');
        if (res.status === 200) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      } catch (error) {
        setAuthenticated(false);
      }
    };

    checkAuthentication();

    }, []);

  if (Authenticated === null) {
    
    return <div>Loading...</div>; 
  }

  return Authenticated ? <Outlet /> : <Navigate to="/login" replace />;
}

export default PrivateRoute;
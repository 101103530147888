import React, { useState, useEffect } from 'react';
import ImgPeople from '../../assets/admin/img/people.png'
export default function Navbar() {
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
    const [searchIcon, setSearchIcon] = useState('bx-search');
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Toggle for responsive search form visibility and icon change
    const toggleSearchForm = (e) => {
        if (window.innerWidth < 576) {
            e.preventDefault();
            setIsSearchFormVisible(!isSearchFormVisible);
            setSearchIcon(isSearchFormVisible ? 'bx-search' : 'bx-x');
        }
    };

    // Effect for handling resize events and setting initial state based on viewport width
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 576 && isSearchFormVisible) {
                setIsSearchFormVisible(false);
                setSearchIcon('bx-search');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call initially to set state based on current viewport width

        return () => window.removeEventListener('resize', handleResize);
    }, [isSearchFormVisible]);

    // Dark mode toggle
    const handleDarkModeToggle = () => {
        setIsDarkMode(!isDarkMode);
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    };
	

    return (
        <nav>
            <i className='bx bx-menu'></i>
            <a href="#" className="nav-link">Categories</a>
            <form action="#">
                <div className={`form-input ${isSearchFormVisible ? 'show' : ''}`}>
                    <input type="search" placeholder="Search..." />
                    <button type="submit" className="search-btn" onClick={toggleSearchForm}>
                        <i className={`bx ${searchIcon}`}></i>
                    </button>
                </div>
            </form>
            <input type="checkbox" id="switch-mode" hidden onChange={handleDarkModeToggle} checked={isDarkMode} />
            <label htmlFor="switch-mode" className="switch-mode"></label>
            <a href="#" className="notification">
                <i className='bx bxs-bell'></i>
                <span className="num">8</span>
            </a>
            <a href="#" className="profile">
                <img src={ImgPeople} alt="Profile" />
            </a>
        </nav>
    );
}

import FirstImageNews from '../../../assets/img/news-3.jpg'
import ImageDetailsNews from '../../../assets/img/news-3.jpg'
import imageNews from '../../../assets/img/34d335d3-0a84-c60d-b131-6da1710f6458G.png'
export default function ThreeNewsDetails() {
  return (
    <>
      <section
        className='d-flex firstSection firstSectionNews justify-content-center align-items-center'
        style={{
          height: '50vh',
          backgroundImage: `url(${FirstImageNews})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></section>
      <section className='d-flex newsSection justify-content-center pt-0'>
        <article className='pb-3 pt-4' style={{ paddingLeft: '0' }}>
          <div
            style={{
              borderBottom: '1px solid #c4c4c4',
              width: '50vw',
              height: '50vh',
              paddingBottom: '20px',
            }}
          >
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              28 May 2022
            </p>
            <h1>PRODUCT OF THE YEAR 2022</h1>
            <img
              src={ImageDetailsNews}
              alt='sectiom Image'
              className=' imgNewsSepecial imageNews '
            />
            <div className='d-flex align-items-center justify-content-center imgNewsSection'>
              <img src={imageNews} alt='sectiom Image' />
            </div>
            <p
              style={{
                padding: '10px 0',
                margin: '0',
                textTransform: 'none',
              }}
            >
              At PromZ.Live22 the inspiration event for Sales & Marketing we did
              show how easy it is to assemble the Glow Mobile Boxes, also simple
              to change the banners and last but not least, they all come in a
              handy Wheely Bag. Glow Mobile Box Battery was awarded not only the
              Jury Prize, but also the Audience Award of the PromZ Promotional
              Product of the Year 2022. We are very proud to bring home two
              prizes! You can order Glow Mobile Boxes at www.glowmobilebox.com
            </p>
            <a
              href='/news'
              style={{
                textTransform: 'none',
                textDecoration: 'none',
                width: '8vw',
                height: '4vh',
                border: '1px solid #b8b8b8',
                borderRadius: '5px',
                color: '#b8b8b8',
              }}
              className='p-1'
            >
              Return to archive
            </a>
          </div>
        </article>
      </section>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'

export default function ViewProducts() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    message: '',
    severity: '',
  })
  useEffect(() => {
    axios.get('/api/view-category').then((res) => {
      if (res.status === 200) {
        setCategories(res.data.categories)
      }
      setLoading(false)
    })
  }, [])
  const deleteCategory = (id) => {
    axios
      .delete(`/api/delete-category/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setAlertInfo({
            show: true,
            message: res.data.message,
            severity: 'success',
          })

          // Update the UI by filtering out the deleted category
          setCategories(categories.filter((category) => category.id !== id))

          setTimeout(() => {
            setAlertInfo({ show: false, message: '', severity: '' })
          }, 3000)
        }
      })
      .catch((error) => {
        // Handle deletion error
        console.error('Deletion error:', error)
        setAlertInfo({
          show: true,
          message: 'Failed to delete category. Please try again.',
          severity: 'error',
        })
      })
  }

  if (loading) {
    return <h4>Loading Categories...</h4>
  }

  return (
    <>
      <div>
        {alertInfo.show && (
          <div>
            <Alert
              icon={<CheckIcon fontSize='inherit' />}
              severity={alertInfo.severity}
            >
              {alertInfo.message}
            </Alert>
            <br />
          </div>
        )}
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => navigate('/admin/category')}
        >
          Add Category
        </button>

        <div className='mt-4'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) =>
                category.sub_categories.length > 0 ? (
                  category.sub_categories.map((sub, index) => (
                    <tr key={sub.id}>
                      <td>{category.name}</td>
                      <td>{sub.nameSubCategory}</td>
                      <td>
                        <Link to={`/admin/edit-sub-category/${category.id}`}>
                          <EditIcon />
                        </Link>
                      </td>
                      <td>
                        <DeleteIcon
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => deleteCategory(category.id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={category.id}>
                    <td>{category.name}</td>
                    <td>N/A</td>
                    <td>
                      <Link to={`/admin/edit-sub-category/${category.id}`}>
                        <EditIcon />
                      </Link>
                    </td>
                    <td>
                    <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => deleteCategory(category.id)} />

                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

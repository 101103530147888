import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function Orders() {
    const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    message: '',
    severity: '',
  })
  useEffect(() => {
    axios.get('/api/orders').then((res) => {
      if (res.status === 200) {
        setOrders(res.data.orders)
      }
      setLoading(false)
    })
  }, [])
  return (
    <>
      <h1>orders</h1>
      <div className='mt-4'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Tracking No</th>
                <th>Phone No</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>
                    {order.id}
                  </td>
                  <td>{order.tracking_no}</td>
                  <td>{order.phone}</td>
                  <td>{order.email}</td>
                  <td>{order.payement_method}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </>
  )
}

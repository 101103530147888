import { Link } from 'react-router-dom'
import Logo from '../../assets/img/512 LOGO.png'

export default function Footer() {
  return (
    <div className='footer d-flex flex-column justify-content-center align-items-center'>
      <center>
        <div className='p-1'>
          <img src={Logo} alt='logo' width='80' />
        </div>
        <div className='d-flex align-items-start justify-content-center'>
          <div className='text-center'>
            <div>
              <b>Glow Mobile Box</b>
              <p>For bespoke frames and light boxes,</p>
              <div>
              </div>
            </div>
          </div>
          <div className='text-center mx-4'>
            <div>
              <b>USA OFFICE AND WAREHOUSE</b>
              <p>Atlanta, Georgia</p>
            </div>
            <Link style={{ color: '#FFCB05' }}>Samir@glowmobilebox.com</Link>
            <div>
              <Link style={{ color: '#FFCB05' }}>+14048256116</Link>
            </div>
          </div>
          <div className='text-center lastFooter'>
            <div className='d-flex '>
              <Link className='px-2' style={{ color: '#Ffffff' }}>
                My account
              </Link>
              <div className='px-2' style={{ color: '#Ffffff' }}>
                Privacy policy
              </div>
            </div>
            <div className='d-flex'>
              <div className='px-2' style={{ color: '#Ffffff' }}>
                Terms & Conditions
              </div>
              <div className='px-2' style={{ color: '#Ffffff' }}>
                Contact us
              </div>
            </div>
          </div>
        </div>
      </center>
    </div>
  )
}

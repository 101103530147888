export default function General() {
  return (
    <>
      <div
        className='accordion'
        id='accordionExample'
        style={{ width: '85vw' }}
      >
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseOne'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              What Fabric do you print on?
            </button>
          </h2>
          <div
            id='collapseOne'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              We use our in-house Super Backlit 190grs fabric.
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseTwo'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              What is SEG?
            </button>
          </h2>
          <div
            id='collapseTwo'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              In short SEG stands for Silicone Edge Graphics, a type of fabric
              print with silicone beading sewn around the edge. The silicone
              edge fits into a groove around the profile of the banner frame.
              This helps the banner maintain tension across the frame. With the
              result of a high-end frameless appearance that everyone loves.
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseThree'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              What method of printing do you use?
            </button>
          </h2>
          <div
            id='collapseThree'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              Our preferred printing method is direct sublimation printing,
              meaning that we are printing directly onto the fabric using
              low-energy sublimation inks.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

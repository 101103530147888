import MasterLayout from './layouts/admin/MasterLayout'
import 'boxicons/css/boxicons.min.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import Dashboard from './components/admin/Dashboard.jsx'
import Home from './components/frontend/Home.jsx'
import Login from './components/frontend/auth/Login.jsx'
import Register from './components/frontend/auth/Register.jsx'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import './index.css'
import axios from 'axios'
import DashboardClient from './components/frontend/DashboardClient.jsx'
import PrivateRoute from './PrivateRoute.jsx'
import Page403 from './components/errors/Page403.jsx'
import Page404 from './components/errors/Page404.jsx'
import Category from './components/admin/Category/Category.jsx'
import ViewCategory from './components/admin/Category/ViewCategory.jsx'
import EditCategory from './components/admin/Category/EditCategory.jsx'
import AddProduct from './components/admin/Product/AddProduct.jsx'
import ViewProducts from './components/admin/Product/ViewProducts.jsx'
import EditProduct from './components/admin/Product/EditProduct.jsx'
import Layout from './layouts/frontend/Layout.jsx'
import Shop from './components/frontend/Shop.jsx'
import CategoriesLayout from './layouts/frontend/CategoriesLayout.jsx'
import ViewProduct from './layouts/frontend/ViewProduct.jsx'
import { Cart } from './layouts/frontend/Cart.jsx'
import Checkout from './layouts/frontend/Checkout.jsx'
import Orders from './components/admin/orders/Orders.jsx'
import AllProducts from './layouts/frontend/AllProducts.jsx'
import TradeShow from './layouts/frontend/TradeShow.jsx'
import PrintInstruction from './layouts/frontend/PrintInstruction.jsx'
import BlogPress from './layouts/frontend/BlogPress.jsx'
import News from './layouts/frontend/News.jsx'
import FirstNewsDetails from './layouts/frontend/NewsDetails/FirstNewsDetails.jsx'
import TwoNewsDetails from './layouts/frontend/NewsDetails/TwoNewsDetails.jsx'
import ThreeNewsDetails from './layouts/frontend/NewsDetails/ThreeNewsDetails.jsx'
import FourNewsDetails from './layouts/frontend/NewsDetails/FourNewsDetails.jsx'
import FiveNewsDetails from './layouts/frontend/NewsDetails/FiveNewsDetails.jsx'
import SixNewsDetails from './layouts/frontend/NewsDetails/SixNewsDetails.jsx'
import SevenNewsDetails from './layouts/frontend/NewsDetails/SevenNewsDetails.jsx'
import EightNewsDetails from './layouts/frontend/NewsDetails/EightNewsDetails.jsx'
import FaqLayout from './layouts/frontend/FaqLayout.jsx'
import FAQ from './layouts/frontend/FAQ.jsx'
import Artwork from './layouts/frontend/Artwork.jsx'
import Banners from './layouts/frontend/Banners.jsx'
import General from './layouts/frontend/General.jsx'
import GlowMobileBox from './layouts/frontend/GlowMobileBox.jsx'
import Miscellaneous from './layouts/frontend/Miscellaneous.jsx'
import OrderProcessing from './layouts/frontend/OrderProcessing.jsx'
 //axios.defaults.baseURL = 'http://127.0.0.1:8000/'
axios.defaults.baseURL = 'https://www.api.glowmobilebox.com/'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.withCredentials = true
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

axios.interceptors.request.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 403) {
      // navigate('/403');
    } else if (error.response.status === 404) {
      // navigate('/404');
    }
  }
)

function App() {

  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path='/403' element={<Page403 />} />
            <Route path='/404' element={<Page404 />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/trade-Show' element={<TradeShow />} />
            <Route path='/print-instruction' element={<PrintInstruction />} />
            <Route path='/blog-press' element={<BlogPress />} />
            <Route path='/news' element={<News />} />
            <Route path='/firstNewsDetails' element={<FirstNewsDetails />} />
            <Route path='/twoNewsDetails' element={<TwoNewsDetails />} />
            <Route path='/threeNewsDetails' element={<ThreeNewsDetails />} />
            <Route path='/fourNewsDetails' element={<FourNewsDetails />} />
            <Route path='/fiveNewsDetails' element={<FiveNewsDetails />} />
            <Route path='/sixNewsDetails' element={<SixNewsDetails />} />
            <Route path='/sevenNewsDetails' element={<SevenNewsDetails />} />
            <Route path='/eightNewsDetails' element={<EightNewsDetails />} />
            <Route path='/dashboard-client' element={<DashboardClient />} />
            <Route element={<FaqLayout />}>
              <Route path='/faq' element={<FAQ />} /> 
              <Route path='/artwork' element={<Artwork />} /> 
              <Route path='/banners' element={<Banners />} /> 
              <Route path='/general' element={<General />} /> 
              <Route path='/glowmobilebox' element={<GlowMobileBox />} /> 
              <Route path='/Miscellaneous' element={<Miscellaneous />} /> 
              <Route path='/orderprocessing' element={<OrderProcessing />} /> 
              
            </Route>
            <Route element={<CategoriesLayout />}>
              <Route path='/allproducts' element={<ViewProduct />} /> 
              <Route  path="/category/:categoryName" element={<ViewProduct />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute allowedRoles={['admin']} />}>
            <Route path='/admin' element={<MasterLayout />}>
              <Route index element={<Dashboard />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='category' element={<Category />} />
              <Route path='view-category' element={<ViewCategory />} />
              <Route path='edit-sub-category/:id' element={<EditCategory />} />
              <Route path='add-product' element={<AddProduct />} />
              <Route path='view-products' element={<ViewProducts />} />
              <Route path='edit-product/:id' element={<EditProduct />} />
              <Route path='orders' element={<Orders />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App

import React, { useState } from 'react'
import axios from 'axios'
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'

const Category = () => {
  const [categoryInput, setCategoryInput] = useState({
    name: '',
    description: '',
    subCategories: [{ nameSubCategory: '', descriptionSubCategory: '' }],
  })
  const [step, setStep] = useState(1) 
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    message: '',
    severity: '',
  })

  const handleCategoryChange = (e) => {
    setCategoryInput({ ...categoryInput, [e.target.name]: e.target.value })
  }

  const handleSubCategoryChange = (index, e) => {
    const newSubCategories = [...categoryInput.subCategories]
    const targetField =
      e.target.name === 'name' ? 'nameSubCategory' : 'descriptionSubCategory'
    newSubCategories[index][targetField] = e.target.value
    setCategoryInput({ ...categoryInput, subCategories: newSubCategories })
  }

  const addSubCategory = () => {
    const newSubCategory = { nameSubCategory: '', descriptionSubCategory: '' }
    setCategoryInput({
      ...categoryInput,
      subCategories: [...categoryInput.subCategories, newSubCategory],
    })
  }

  const removeSubCategory = (index) => {
    const newSubCategories = [...categoryInput.subCategories]
    newSubCategories.splice(index, 1)
    setCategoryInput({ ...categoryInput, subCategories: newSubCategories })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredSubCategories = categoryInput.subCategories.filter(sc => sc.nameSubCategory);

    const dataToSend = {
        ...categoryInput,
        subCategories: filteredSubCategories.length > 0 ? filteredSubCategories : undefined,
    };
    axios
      .post('/api/store-category',  dataToSend)
      .then((response) => {
        if (response.data.status === 200) {
          // Reset the form state
          setCategoryInput({
            name: '',
            description: '',
            subCategories: [
              { nameSubCategory: '', descriptionSubCategory: '' },
            ],
          })

          // Show success alert
          setAlertInfo({
            show: true,
            message: response.data.message,
            severity: 'success',
          })

          setTimeout(() => {
            setAlertInfo({ show: false, message: '', severity: '' })
          }, 3000)
        } else {
          // Show error for server-side validation issues or other problems
          setAlertInfo({
            show: true,
            message: 'Error saving category',
            severity: 'error',
          })
        }
      })
      .catch((error) => {
        // Show error for network issues or server errors
        setAlertInfo({
          show: true,
          message: 'An error occurred',
          severity: 'error',
        })
      })
  }

  return (
    <div className='container mt-5'>
      {alertInfo.show && (
        <div>
          <Alert
            icon={<CheckIcon fontSize='inherit' />}
            severity={alertInfo.severity}
          >
            {alertInfo.message}
          </Alert>
          <br />
        </div>
      )}
      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <button
            className={`nav-link ${step === 1 ? 'active' : ''}`}
            onClick={() => setStep(1)}
          >
            Category
          </button>
        </li>
        <li className='nav-item'>
          <button
            className={`nav-link ${step === 2 ? 'active' : ''}`}
            onClick={() => setStep(2)}
          >
            Sub Category
          </button>
        </li>
      </ul>
      <br />
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <div style={{ width: '50vw' }}>
              <div className='form-group d-flex justify-content-between'>
                <label htmlFor='categoryName'>Category Name</label>
                <input
                  type='text'
                  className='form-control'
                  id='categoryName'
                  name='name'
                  value={categoryInput.name}
                  onChange={handleCategoryChange}
                  style={{ width: '35vw' }}
                />
              </div>
              <br />
              <div className='form-group d-flex justify-content-between'>
                <label htmlFor='description'>Description</label>
                <textarea
                  className='form-control'
                  id='description'
                  name='description'
                  value={categoryInput.description}
                  onChange={handleCategoryChange}
                  style={{ width: '35vw' }}
                />
              </div>
            </div>
            <br />
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => setStep(2)}
            >
              Next
            </button>
          </>
        )}

        {step === 2 && (
          <>
            {categoryInput.subCategories.map((subCategory, index) => (
              <div key={index} style={{ width: '55vw' }}>
                <div className='form-group d-flex justify-content-between'>
                  <label htmlFor='nameSubCategory'>Sub Category Name</label>
                  <input
                    type='text'
                    className='form-control'
                    name='name'
                    value={subCategory.nameSubCategory}
                    onChange={(e) => handleSubCategoryChange(index, e)}
                    placeholder=''
                    style={{ width: '35vw' }}
                  />
                </div>
                <br />
                <div className='form-group d-flex justify-content-between'>
                  <label htmlFor='descriptionSubCategory'>
                    Sub Category Description
                  </label>
                  <textarea
                    className='form-control'
                    name='description'
                    value={subCategory.descriptionSubCategory}
                    onChange={(e) => handleSubCategoryChange(index, e)}
                    placeholder=''
                    style={{ width: '35vw' }}
                  />
                </div>
                <br />
                {categoryInput.subCategories.length > 1 && (
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => removeSubCategory(index)}
                  >
                    Remove
                  </button>
                )}
                <hr />
              </div>
            ))}
            <br />
            <button
              type='button'
              className='btn btn-secondary'
              onClick={addSubCategory}
            >
              Add Another Sub Category
            </button>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </>
        )}
      </form>
    </div>
  )
}

export default Category

export default function GlowMobileBox() {
  return (
    <>
      <div
        className='accordion'
        id='accordionExample'
        style={{ width: '85vw' }}
      >
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseOne'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              What is Super Backlit 190grs
            </button>
          </h2>
          <div
            id='collapseOne'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              Super Backlit is specially produced for high quality light boxes.
              It can be printed with Dye Sublimation, direct to fabric or by
              transfer. Super Backlit is a very tightly woven fabric that does
              not have pinholes and therefore is PE coating free. The absence of
              PE coating makes it possible to fold this fabric for shipping.
              Super Backlit has a very wide color gamut and extremely high light
              output, and on top of this has great elasticity. <br />{' '}
              Specifications:
              <ul>
                <li>Material 100% polyester</li>
                <li>B1 fire retardant</li>
                <li>Width 320 cm / 10.5 feet</li>
                <li>Roll length 100 meter / 109 yards</li>
                <li>Weight 190 g/m2</li>
                <li>Transmission 42%</li>
                <li>Shrinkage Length less than 2%</li>
                <li>Shrinkage Width less than 3%</li>
                <li>Packaging each roll in a PE bag and cardboard core</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

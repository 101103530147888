import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import '../../assets/admin/style.css';

import { Outlet } from 'react-router-dom';

const MasterLayout = () => {
  return (
    <div>
      <Sidebar />
      <section id="content">
      <Navbar />
      <main>
        <Outlet /> 
      </main>
      </section>
      
    </div>
  );
};

export default MasterLayout;

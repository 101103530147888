import SectiomImage from '../../assets/img/11.png'
import DoneIcon from '@mui/icons-material/Done'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded'
import InventoryIcon from '@mui/icons-material/Inventory'
import SectionSecondImage from '../../assets/img/2.jpg'
import ThirdSectiomImage from '../../assets/img/3.png'
import ForthSectiomImage from '../../assets/img/4.jpg'
import LastImage from '../../assets/img/5.jpg'
import Video from '../../assets/img/video.jpg'
import { Link } from 'react-router-dom'
export default function Home() {
  return (
    <>
      <section className='firstSection'>
        <article>
          <h1 className='pt-4 titre'>GLOW MOBILE BOX</h1>
          <span className='secondTitle'>
            VISUAL MOBILE PROMOTION STARTS HERE
          </span>
          <p>
            All essentials for promoting anything on the spot in one bag, easy
            setup with no tools required. It’s all in the bag! Developed for
            mobility and durability when doing high impact presentations in
            different locations.
          </p>
          <ul>
            <li className='d-flex'>
              <DoneIcon
                sx={{
                  color: 'black',
                  fontSize: 20,
                  fontWeight: 'bold',
                }}
              />
              <b className='ps-2'>Setup in 5 minutes</b>
            </li>
            <li className='d-flex'>
              <DoneIcon
                sx={{
                  color: 'black',
                  fontSize: 20,
                  fontWeight: 'bold',
                }}
              />
              <b className='ps-2'>LED lighting is included for extra impact</b>
            </li>
            <li className='d-flex'>
              <DoneIcon
                sx={{
                  color: 'black',
                  fontSize: 20,
                  fontWeight: 'bold',
                }}
              />
              <b className='ps-2'>Frames in sizes of up to 9.84 ft</b>
            </li>
            <li className='d-flex'>
              <DoneIcon
                sx={{
                  color: 'black',
                  fontSize: 20,
                  fontWeight: 'bold',
                }}
              />
              <b className='ps-2'>
                Connect multiple frames for an even bigger impression
              </b>
            </li>
          </ul>
          <p className='mb-3'>
            Professionals that travel from venue to venue doing presentations
            love our product. Ideal for trade shows, sales presentations,
            training sessions and pop-up promotions!
          </p>
          <Link
            className='btnSection '
            style={{
              backgroundColor: '#FFCB05',
              padding: '8px',
              textDecoration: 'none',
              textTransform: 'none',
              color: '#181818',
            }}
            to='/allproducts'
          >
            SEE IT IN ACTION <PlayCircleOutlineRoundedIcon />
          </Link>
        </article>
        <aside>
          <img src={SectiomImage} alt='sectiom Image' width='auto' />
        </aside>
      </section>
      <section
        className='secondSection'
        style={{
          boxShadow: ' 0px -40px 40px -50px rgba(0, 0, 0, 0.3)',
        }}
      >
        <article>
          <h1>MAKE AN IMPRESSION ON EVERY EVENT</h1>
          <span className='secondTitle'>
            STAND OUT WITH A BACKLIT GRAPHIC THAT TRAVELS
          </span>
          <p>
            Your signage needs to pop. Your logo needs to stand out. Your art
            needs to stop a passerby in their tracks. You need to WOW – with
            ease and portability.
          </p>
          <div className='d-flex'>
            <img
              src={SectionSecondImage}
              alt='sectiom Image'
              className='SectionSecondImage'
            />
          </div>
          <Link className='btnSecondSectionTwo' to='/print-instruction'>
            CHOOSE YOUR SIZE <InventoryIcon />
          </Link>
        </article>

        <aside className='align-self-end asideSecondSection'>
          <div style={{ fontFamily: ' "Source Sans 3", sans-serif' }}>
            <b>Set it up and flip the switch</b>
            <p>
              Travel much? Glow Mobile Box has you covered. With a durable yet
              lightweight aluminum and ABS framework that slides and snaps
              together, customized and foldable SEG fabric graphics and
              brilliant LED lighting, Glow Mobile Box transforms your display
              and dazzles your customers every time you flip the switch.
            </p>
          </div>
          <div style={{ fontFamily: ' "Source Sans 3", sans-serif' }}>
            <b>Shine more light on the case with Glow Mobile Box</b>
            <p>
              It’s sleekly portable in a heavy-duty, double-wheeled canvas bag
              that rolls easily over every surface. Set-up is a breeze – with no
              tools required – which means less stress for you and your team.
              And when you need to make an even bolder statement, you can
              connect several Mobile Light Boxes together to create bigger
              stands or structures.
            </p>
          </div>
        </aside>
      </section>
      <section
        className='thirdSection d-flex align-items-center'
        style={{
          boxShadow: ' 0px -40px 40px -50px rgba(0, 0, 0, 0.3)',
        }}
      >
        <article>
          <h1>ORDER YOUR ACCESSORIES</h1>
          <span className='secondTitle'>
            IN NEED OF AN EXTRA BANNER OR ACCESSORIES?
          </span>
          <p className='mt-2'>
            Expand your appearance, add some extra’s to your GMB-kit and give a
            boost to the attention of your public.
          </p>
          <div className='groupBtn mt-1'>
            <Link className='btnSecondSection' to='/allproducts'>
              GET YOUR BANNER <InventoryIcon />
            </Link>

            <Link className='btnSection ' to='/allproducts'>
              GET YOUR ACCESSORIES <PlayCircleOutlineRoundedIcon />
            </Link>
          </div>
        </article>

        <aside>
          <img
            src={ThirdSectiomImage}
            alt='sectiom Image'
            width='80'
            className='secondImage'
          />
        </aside>
      </section>
      <section
        className='forthSection d-flex align-items-center'
        style={{
          boxShadow: ' 0px -40px 40px -50px rgba(0, 0, 0, 0.3)',
        }}
      >
        <aside className='ForthAside align-self-end'>
          <img
            src={ForthSectiomImage}
            alt='sectiom Image'
            className='ForthSectiomImage'
          />
        </aside>
        <article className='pb-4'>
          <h1 className='mt-2'>IT’S ALL IN THE BAG</h1>
          <span className='secondTitle'>PACKED WITH EVERYTHING YOU NEED</span>
          <div className=' d-flex pt-3 mt-1 globalcardSection'>
            <div className='cardSection'>
              <b>Integrated LED lighting</b>
              <p>
                The LED Power modules are inside the profile of the frame on top
                and bottom and can not be damaged during transport or handling
                due to its slick design
              </p>
            </div>
            <div className='cardSection'>
              <b>Durable flight case</b>
              <p>
                The heavy duty polyester flight case is made out of recycled PET
                bottles and has double wheels. The hard foam inlay protects
                every part of the frame.
              </p>
            </div>
          </div>
          <div className='d-flex mb-4 globalcardSection'>
            <div className='cardSection'>
              <b>Evenly lit surface</b>
              <p>
                The Power LED Modules provide an even lit surface no matter what
                size Glow Mobile Box you choose
              </p>
            </div>
            <div className='cardSection'>
              <b>Connect frames together</b>
              <p>
                With simple connectors several stand alone Glow Mobile Boxes
                will turn in to a bigger stand.
              </p>
            </div>
          </div>
          <Link className='btnSecondSection' to='/allproducts'>
            CHOOSE SIZE FOR TECHNICAL SPECS
          </Link>
        </article>
      </section>
      <section
        className='fiveSection d-flex align-items-center'
        style={{
          boxShadow: ' 0px -40px 40px -50px rgba(0, 0, 0, 0.3)',
        }}
      >
        <article className='pb-3' style={{ width: '100vw' }}>
          <h1 className='pt-3'>AN ALL IN ONE SOLUTION</h1>
          <span className='secondTitle'>
            WE PRINT THE BACKLIT GRAPHIC TO GO WITH THE BOX
          </span>
          <p>
            We can offer you an A to Z delivery of your Glow Mobile Box,
            including the print and extended modules.
          </p>
          <div className='d-flex fiveSectionDiv'>
            <div className='d-flex'>
              <div className='lastImageCard fiveImageCard'>
                <img
                  src={LastImage}
                  alt='sectiom Image'
                  width='80'
                  className='fiveImage'
                />
              </div>
              <div className='lastImageCard fiveImageCard'>
                <b>It’s in the bag.</b>
                <p>
                  When we say it’s in the bag, we mean everything you need is in
                  the bag. All essentials are included to have your big
                  impression up in minutes, ready to go when you’re done.
                </p>
              </div>
            </div>
            <div className='d-flex'>
              <div className='lastImageCard fiveImageCard'>
                <b>No tools required.</b>
                <p>
                  Glow Mobile Box literally slides and snaps together in minutes
                  using no tools. We’ve actually timed setting up the 6.56 ft by
                  3.28 ft: 4m46s on a new client’s second attempt. So no false
                  promises, just an easy setup.
                </p>
              </div>
              <div className='lastImageCard fiveImageCard'>
                <b>Lightweight and portable</b>
                <p>
                  Keeping mobility and durability in mind, we selected the best
                  parts for the job. Aluminum frames. Foldable SEG fabric
                  graphics. Heavy-duty canvas bags with handle and wheels for
                  easy transport.
                </p>
              </div>
            </div>
          </div>
          <Link className='btnSecondSection' to='/allproducts'>
            DISCOVER SIZES AND PRINT OPTIONS <InventoryIcon />
          </Link>
        </article>
      </section>
      <section
        className='lastSection d-flex flex-column align-items-center mt-4 justify-content-center'
        style={{
          boxShadow: ' 0px -40px 40px -50px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#FFCB05',
          textAlign: 'center',
        }}
      >
        <article className='lastArticle pb-3'>
          <div>
            <h1 className='pt-3'>SEE THE GLOW MOBILE BOX IN ACTION</h1>
            <p>
              Have a look at our booth in 360 views! André America will show you
              around.
            </p>
            <div className='d-flex my-4'>
              <div className='lastImage'>
                <iframe
                  src='https://www.youtube.com/embed/y3r_lFygYko'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '10vh' }}>
            <h1 className='mt-2 pt-3'>Setup in less than 5 minutes</h1>
            <p>
              Say goodbye to the average rollup banner, here’s the next step in
              mobile promotion. Check out this product demonstration to explore
              the ease of use and get inspired by it’s modules and
              expandability.
            </p>
            <div className='d-flex my-4'>
              <div className='lastImage '>
                <img src={Video} alt='' />
              </div>
            </div>
          </div>
          <div className='lastPart'>
            <h1 className='pt-3'>
              Looking for that top-notch mobile promotional solution?
            </h1>
            <p>
              We make sure you make the right impression, lighting up your Glow
              Mobile Box.
            </p>
          </div>
        </article>
          <Link className='btnSecondSection' to='/allproducts'>
            DISCOVER SIZES AND PRINT OPTIONS <InventoryIcon />
          </Link>
      </section>
      {/* <div
                    className="elfsight-app-640cf1ba-5d79-4194-9396-4db05dd520ab"
                    data-elfsight-app-lazy
                    style={{
                        width: "30vw",
                        height: "65vh",
                        position: "fixed",
                        bottom: "0",
                        right: "0"
                    }}
                ></div> */}
    </>
  )
}

import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Paypal from '../../assets/img/PayPal.png'
import { IconButton } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import axios from 'axios'
import ClearIcon from '@mui/icons-material/Clear'
export function Cart() {
  const apiUrl = 'https://www.api.glowmobilebox.com/'
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [quantity, setQuantity] = useState(1)
  const [cart, setCart] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let isMounted = true
      try {
        const res = await axios.get('/api/cart')
        if (isMounted) {
          if (
            res.data &&
            res.data.hasOwnProperty('status') &&
            res.data.hasOwnProperty('cart')
          ) {
            if (res.data.status === 200) {
              setCart(res.data.cart)
            } else if (res.data.status === 401) {
              const errorMessage = res.data.message || 'Unauthorized'
              // swal("Warning", errorMessage, "error");
            } else {
              console.error('Invalid response format:', res)
            }
          } else {
            console.error('Invalid response format:', res)
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        isMounted && (isMounted = false)
      }
    }

    fetchData()
  }, [])

  const handleDecrement = (cart_id) => {
    setCart((cart) =>
      cart.map((item) =>
        cart_id === item.id
          ? {
              ...item,
              product_qty: Math.max(item.product_qty - 1, 0),
            }
          : item
      )
    )
    updateCartQuantity(cart_id, 'dec')
  }
  const handleIncrement = (cart_id) => {
    setCart((cart) =>
      cart.map((item) =>
        cart_id === item.id
          ? {
              ...item,
              product_qty: Math.min(item.product_qty + 1, 10),
            }
          : item
      )
    )
    updateCartQuantity(cart_id, 'inc')
  }

  function updateCartQuantity(cart_id, scope) {
    axios.put(`/api/cart-updatequantity/${cart_id}/${scope}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success", res.data.message, "success");
      }
    })
  }
  const deleteCartItem = (e, cart_id) => {
    e.preventDefault()
    const thisClicked = e.currentTarget
    thisClicked.innerText = 'Removing'

    axios.delete(`/api/delete-cartitem/${cart_id}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success", "Cart item removed successfully", "success");
        thisClicked.closest('tr').remove() // Fix: Use 'tr' instead of 'TableRow'
      } else if (res.data.status === 404) {
        // swal("Error", "Cart item not found", "error");
        thisClicked.innerText = 'Remove'
      }
    })
  }
  const determinePrice = (product) => {
    return product?.originalPrice ?? 0
  }
  const subtotal = cart.reduce((acc, item) => {
    return (
      acc +
      parseFloat(determinePrice(item.product)) * parseInt(item.product_qty, 10)
    )
  }, 0)
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center firstSec mt-4'
        style={{ height: '40vh' }}
      >
        <article>
          <div className='text-center '>
            <ul className='d-flex justify-content-between'>
              <li>
                <Link
                  className={`nav-link ${step === 1 ? 'active' : ''}`}
                  onClick={() => setStep(1)}
                  style={{ fontSize: '24px', color: '#FFCB05' }}
                >
                  Shopping Cart
                </Link>
              </li>
              <ArrowForwardIosIcon className='mt-2' />
              <li>
                <Link
                  className={`nav-link ${step === 2 ? 'active' : ''}`}
                  onClick={() => setStep(2)}
                  style={{ fontSize: '24px' }}
                >
                  Checkout
                </Link>
              </li>
              <ArrowForwardIosIcon className='mt-2' />
              <li>
                <Link
                  className={`nav-link ${step === 3 ? 'active' : ''}`}
                  onClick={() => setStep(3)}
                  style={{ fontSize: '24px', color: 'gray' }}
                >
                  Order Complete
                </Link>
              </li>
            </ul>
          </div>
        </article>
      </section>
      <div className='d-flex justify-content-around'>
        <div style={{ width: '60vw' }}>
          <TableContainer component={Paper}>
            <Table size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align='right'>PRODUCT</TableCell>
                  <TableCell align='right'>PRICE</TableCell>
                  <TableCell align='right'>QUANTITY</TableCell>
                  <TableCell align='right'>SUBTOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((item) => {
                  return (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ position: 'relative' }}
                      >
                        <img
                          src={`${apiUrl}storage/${item.product?.image || 'default.png'}`}
                          alt={item.product?.name || 'No product name'}
                          style={{ width: '60px', height: '40px' }}
                        />
                        <ClearIcon
                          sx={{ fontSize: 5 }}
                          style={{
                            position: 'absolute',
                            top: '6px',
                            right: '2px',
                            cursor: 'pointer',
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            backgroundColor: '#fff',
                            color: '#222529',
                            borderRadius: '50%',
                            boxShadow: '0 2px 6px 0 rgba(0,0,0,0.4)',
                            zIndex: '3',
                          }}
                          onClick={(e) => deleteCartItem(e, item.id)}
                        />
                      </TableCell>
                      <TableCell align='right'>{item.product?.name || 'Product not found'}</TableCell>
                      <TableCell align='right'>
                        $ {determinePrice(item.product)}
                      </TableCell>
                      <TableCell align='right'>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Link
                            onClick={() => handleDecrement(item.id)}
                            style={{
                              textTransform: 'none',
                              textDecoration: 'none',
                              fontSize: '24px',
                              background: '#181818',
                              color: '#ffffff',
                              width: '2vw',
                              height: '6vh',
                              textAlign: 'center',
                            }}
                          >
                            <RemoveIcon />
                          </Link>
                          <Typography
                            variant='h6'
                            style={{
                              margin: '0 10px',
                            }}
                          >
                            {item.product_qty}
                          </Typography>
                          <Link
                            onClick={() => handleIncrement(item.id)}
                            style={{
                              textTransform: 'none',
                              textDecoration: 'none',
                              fontSize: '24px',
                              background: '#181818',
                              color: '#ffffff',
                              width: '2vw',
                              height: '6vh',
                              textAlign: 'center',
                            }}
                          >
                            <AddIcon />
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell align='right'>
                        ${' '}
                        {parseFloat(determinePrice(item.product)) *
                          parseInt(item.product_qty, 10)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div
          style={{
            width: '30vw',
            boxShadow: '0 15px 25px rgba(28, 28, 28, 0.15)',
            zIndex: '2',
          }}
        >
          <h6 className='mt-3 ms-4'>CART TOTALS</h6>
          <div className='d-flex justify-content-between mx-4 mt-4'>
            <h6>SUBTOTAL:</h6>
            <p>${subtotal.toFixed(2)}</p>
          </div>
          <hr />
          <div className='d-flex justify-content-between mx-4'>
            <p>Tax</p>
            <p>Taxes will be calculated at checkout</p>
          </div>
          <hr />
          <div className='d-flex justify-content-between mx-4'>
            <b>TOTAL:</b>
            <b>${subtotal.toFixed(2)}</b>
          </div>
          <br />
          <center>
            <button
              type='button'
              style={{
                fontsize: '15px',
                background: '#181818',
                color: 'white',
                border: 'none',
                letterSpacing: '-0.015em',
                marginBottom: '10px',
                textTransform: 'uppercase',
                fontWeight: '600',
                padding: '.8rem 2rem',
                borderRadius: '2rem',
              }}
              onClick={() => navigate('/checkout')}
            >
              Proceed to checkout <ArrowForwardIcon sx={{ fontSize: 15 }} />
            </button>
          </center>
          {/* <center>
            <button
              type='button'
              className=''
              onClick={() => setStep(2)}
              style={{
                fontsize: '15px',
                background: '#181818',
                color: 'white',
                border: 'none',
                letterSpacing: '-0.015em',
                marginBottom: '10px',
                textTransform: 'uppercase',
                fontWeight: '600',
                padding: '.8rem 2rem',
                width: '19vw',
              }}
            >
              <img src={Paypal} style={{ width: '6vw' }} alt='' />
              <span
                className='ms-2 mt-4'
                style={{
                  opacity: '0.8',
                  fontSize: '16px',
                  overflow: 'hidden',
                  animation: 'show-text 1s 0s forwards',
                  lineHeight: '24px',
                  color: 'white',
                }}
              >
                Payer
              </span>
            </button>
          </center> */}
        </div>
      </div>
      <br />
    </>
  )
}

import { Button } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import StarIcon from '@mui/icons-material/Star'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, IconButton } from '@mui/material'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import RemoveIcon from '@mui/icons-material/Remove'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import MyPagination from './MyPagination'

export default function ViewProduct() {
  const { categoryName } = useParams();
   // const apiUrl = 'http://127.0.0.1:8000/';
  const apiUrl = 'https://www.api.glowmobilebox.com/'

  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [popupMessage, setPopupMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  useEffect(() => {
    const endpoint = categoryName ? `/api/fetchProducts/${categoryName}` : '/api/fetchProducts/all';
    axios.get(endpoint)
      .then((res) => {
        if (res.status === 200 && res.data.product_data && res.data.product_data.products) {
          setProducts(res.data.product_data.products);
        } else {
          console.error('Failed to fetch products:', res);
          setProducts([]);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch products:', error);
        setProducts([]);
      });
  }, [categoryName]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClickOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleAddToCart = (product) => {
    setSelectedProduct(product);
    submitAddToCart(product);
  };

  const submitAddToCart = (product) => {
    const data = {
      product_id: product.id,
      product_qty: quantity,
    };

    axios.post(`/api/add-to-cart`, data)
      .then((res) => {
        if (res.data.status === 201) {
          setPopupMessage('Product added to cart successfully!');
        } else {
          setPopupMessage('Product added to cart!');
        }
        setOpen(true);
      })
      .catch((error) => {
        console.error('Error adding to cart:', error);
        setPopupMessage('Failed to add product to cart.');
        setOpen(true);
      });
  };

  const PopupModal = ({ open, onClose, message }) => {
    if (!open) return null;

    return (
      <div className='popup-overlay'>
        <div className='popup-content'>
          <p>{message}</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  return (
    <>
      <PopupModal open={open} onClose={handleClose} message={popupMessage} />
      {products.length > 0 ? (
        currentProducts.map((product) => (
          <div className='card' key={product.id}>
            <img
              src={`${apiUrl}storage/${product.image}`}
              className='card-img-top'
              alt={product.name}
            />
            <div className='card-body text-center'>
              <h6
                className='card-title'
                onClick={() => handleClickOpen(product)}
              >
                {product.name}
              </h6>
              <p className='card-text'>
                <span className='me-2'>
                  <StarIcon
                    sx={{
                      color: 'gold',
                      fontSize: 15,
                    }}
                  />
                  <StarIcon
                    sx={{
                      color: 'gold',
                      fontSize: 15,
                    }}
                  />
                  <StarIcon
                    sx={{
                      color: 'gold',
                      fontSize: 15,
                    }}
                  />
                  <StarIcon
                    sx={{
                      color: 'gold',
                      fontSize: 15,
                    }}
                  />
                  <StarIcon
                    sx={{
                      color: 'gold',
                      fontSize: 15,
                    }}
                  />
                </span>
                <span>6 reviews</span>
              </p>
              <p className='card-text'>
                {product.originalPrice && (
                  <span className='me-2'>
                    ${parseFloat(product.originalPrice).toFixed(2)} USD
                  </span>
                )}
                {/* {product.sellingPrice && (
                    <span>${(+product.sellingPrice).toFixed(2)} USD</span>
                  )} */}
              </p>
              <Button
                className='btn btnToCart'
                style={{
                  border: '1px solid #FFCB05',
                  color: '#FFCB05',
                }}
                onClick={() => submitAddToCart(product)}
              >
                Add to cart
              </Button>
            </div>
          </div>
        ))
      ) : (
        <p>No products found.</p>
      )}
      {selectedProduct && (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault()
              const formData = new FormData(event.currentTarget)
              const formJson = Object.fromEntries(formData.entries())
              const email = formJson.email
              console.log(email)
              handleClose()
            },
            style: {
              width: '75vw',
              maxWidth: '75vw',
            },
          }}
        >
          <Box display='flex' flexDirection='row'>
            <DialogContent>
              <img
                style={{ width: '20vw' }}
                src={`${apiUrl}storage/${selectedProduct.image}`}
                alt={selectedProduct.name}
              />
            </DialogContent>
            <DialogContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
              >
                <div>
                  <DialogTitle style={{ paddingLeft: '0', paddingBottom: '0' }}>
                    <b>{selectedProduct.name}</b>
                  </DialogTitle>
                  <Typography>
                    <StarBorderIcon
                      sx={{
                        fontSize: 15,
                        color: 'gold',
                      }}
                    />
                    <StarBorderIcon
                      sx={{
                        fontSize: 15,
                        color: 'gold',
                      }}
                    />
                    <StarBorderIcon
                      sx={{
                        fontSize: 15,
                        color: 'gold',
                      }}
                    />
                    <StarBorderIcon
                      sx={{
                        fontSize: 15,
                        color: 'gold',
                      }}
                    />
                    <StarBorderIcon
                      sx={{
                        fontSize: 15,
                        color: 'gold',
                      }}
                    />
                  </Typography>
                  <Typography>
                    <b>Total Price:</b> $
                    {selectedProduct.originalPrice * quantity.toFixed(2)}
                  </Typography>
                  <Typography>
                    <b>Availability:</b> {selectedProduct.stock} in stock
                  </Typography>
                  <Typography>
                    <b>SKU:</b> {selectedProduct.sku}
                  </Typography>
                  <Typography>
                    <b>Category:</b> {selectedProduct.category_id}
                  </Typography>
                </div>
              </Box>
              <Box>
                <DialogActions>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={handleDecrement}
                      color='gold'
                      aria-label='remove'
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Typography
                      variant='h6'
                      style={{
                        margin: '0 10px',
                      }}
                    >
                      {quantity}
                    </Typography>
                    <IconButton
                      onClick={handleIncrement}
                      color='gold'
                      aria-label='add'
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                </DialogActions>
                <DialogActions>
                  <Button
                    className='btn'
                    style={{
                      border: '1px solid #FFCB05',
                      color: '#FFCB05',
                    }}
                    onClick={() => handleAddToCart(selectedProduct)}
                  >
                    Add to cart
                  </Button>
                </DialogActions>
              </Box>
            </DialogContent>
          </Box>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
      <PopupModal open={open} onClose={handleClose} message={popupMessage} />
      <MyPagination
        count={Math.ceil(products.length / productsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
      />
    </>
  )
}

export default function FAQ() {
    return (
        <>
            <div
                className="accordion"
                id="accordionExample"
                style={{ height:'auto'}}
            >
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Broken or lost parts
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Not to worry, we carry the most crucial spare parts
                            in stock. If you already know what you need check
                            website : Spare parts – Glow Mobile Box In case you
                            are unsure of what is need or if it is repairable,
                            please contact us at Samir@glowmobilebox.com
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Dirt or Stains
                        </button>
                    </h2>
                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Not to worry, we carry the most crucial spare parts
                            in stock. If you already know what you need check
                            website : Spare parts – Glow Mobile Box In case you
                            are unsure of what is need or if it is repairable,
                            please contact us at sales@mobilelightbox.us
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTree"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Do you keep my artwork on file?
                        </button>
                    </h2>
                    <div
                        id="collapseTree"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Not to worry, we carry the most crucial spare parts
                            in stock. If you already know what you need check
                            website : Spare parts – Glow Mobile Box In case you
                            are unsure of what is need or if it is repairable,
                            please contact us at sales@mobilelightbox.us
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseForth"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Handling
                        </button>
                    </h2>
                    <div
                        id="collapseForth"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Always handle fabric in a clean area with clean
                            hands or gloves.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            How do I submit artwork files
                        </button>
                    </h2>
                    <div
                        id="collapseFive"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            You can submit your print-ready files either via
                            e-mail Samir@mobilebox.us or via our WeTransfer
                            site: https://glowmobilebox.com/ Please
                            mention your web shop order number when you are
                            sending the print files.For further information see
                            you print instructions page: Print Instructions –
                            Glow Mobile Box
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Installation
                        </button>
                    </h2>
                    <div
                        id="collapseSix"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Insert the silicone edge into the frame groove,
                            starting from the four corners. Then pull the
                            banner’s edge and insert the remaining sides.
                            Gradually insert the silicon edging into the grooves
                            all the way around the frame. Ensure that the banner
                            is properly aligned and centered within the frame.
                            Adjust the tension by gently pulling on the banner
                            and repositioning the silicone edging if needed.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Rush Policy
                        </button>
                    </h2>
                    <div
                        id="collapseSeven"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            We understand that on occasion something needs to be
                            done quickly. For orders that need to be expedited
                            please contact us via phone or e-mail. We offer a 2
                            Day Rush option for those situations.
                            <ul>
                                <li>
                                    {" "}
                                    2-Day Rush fee: 25% of the order total, $50
                                    minimum.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Special request
                        </button>
                    </h2>
                    <div
                        id="collapseEight"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            If you have any special requests or specific in-hand
                            dates. Please mention those in the order notes or
                            contact us at Samir@glowmobilebox.com
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            Storage
                        </button>
                    </h2>
                    <div
                        id="collapseNine"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            To ensure the quality of your banners, we recommend
                            folding loosely and facing inward. Ideally packed
                            back in the same bag that they were delivered in. If
                            possible do not stack folder banners on top of each
                            other, this will help precent creases or ink
                            transfers.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            What can I do if an LED light goes out?
                        </button>
                    </h2>
                    <div
                        id="collapseTen"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            The built-in LED light modules are easily replaced.
                            You can just pop them out with a screw drive and set
                            a new module in. Please contact us at
                            Samir@glowmobilebox.com for detailed instructions.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            What Fabric do you print on?
                        </button>
                    </h2>
                    <div
                        id="collapseEleven"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            We use our in-house Super Backlit 190grs fabric.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            What is SEG?
                        </button>
                    </h2>
                    <div
                        id="collapseTwelve"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            In short SEG stands for Silicone Edge Graphics, a
                            type of fabric print with silicone beading sewn
                            around the edge. The silicone edge fits into a
                            groove around the profile of the banner frame. This
                            helps the banner maintain tension across the frame.
                            With the result of a high-end frameless appearance
                            that everyone loves.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            What is Super Backlit 190grs
                        </button>
                    </h2>
                    <div
                        id="collapseThirteen"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Super Backlit is specially produced for high quality
                            light boxes. It can be printed with Dye Sublimation,
                            direct to fabric or by transfer. Super Backlit is a
                            very tightly woven fabric that does not have
                            pinholes and therefore is PE coating free. The
                            absence of PE coating makes it possible to fold this
                            fabric for shipping. Super Backlit has a very wide
                            color gamut and extremely high light output, and on
                            top of this has great elasticity. Specifications:
                            <ul>
                                <li>Material 100% polyester</li>
                                <li>B1 fire retardant</li>
                                <li>Width 320 cm / 10.5 feet</li>
                                <li>Roll length 100 meter / 109 yards</li>
                                <li>Weight 190 g/m2</li>
                                <li>Transmission 42%</li>
                                <li>Shrinkage Length less than 2%</li>
                                <li>Shrinkage Width less than 3%</li>
                                <li>
                                    Packaging each roll in a PE bag and
                                    cardboard core
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourth"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            What method of printing do you use?
                        </button>
                    </h2>
                    <div
                        id="collapseFourth"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Our preferred printing method is direct sublimation
                            printing, meaning that we are printing directly onto
                            the fabric using low-energy sublimation inks.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        borderRadius: "0",
                        marginBottom: "10px",
                    }}
                >
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFiften"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{
                                color: "#FFCB05",
                                fontWeight: "500",
                                borderLeft: "4px solid #FFCB05",
                                borderRadius: "0",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            What type of artwork files do you accept?
                        </button>
                    </h2>
                    <div
                        id="collapseFiften"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            We prefer print-ready files to be submitted in PDF
                            or TIFF format. Though we can accept the following
                            file formats.
                            <ul>
                                <li>InDesign (.indd)</li>
                                <li>Illustrator (.ai)</li>
                                <li>Photoshop (.psd)</li>
                                <li>Encapsulated PostScript (.eps)</li>
                                <li>Portable Document Format (.pdf)</li>
                                <li>Tagged Image File Format (.tiff)</li>
                            </ul>
                            Please consult our print instructions page for all
                            details: Print Instructions – Glow Mobile Box
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
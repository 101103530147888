import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import LanguageIcon from "@mui/icons-material/Language";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Visa from "../../assets/frontend/img/visa-logo-e1577976644815.png";
import TikTokIcon from "../../assets/frontend/img/tiktok.png";
import Paypal from "../../assets/frontend/img/paypal-logo-e1577976629557.png";

export default function HeaderTop() {
    
    return (
        <>
            <div className="header1">
                <div className="d-flex align-items-center HeaderTopPart1">
                    <div className="d-flex align-items-center me-2">
                        <div className=" me-2">
                            <StarIcon sx={{ color: "gold", fontSize: 15 }} />
                            <StarIcon sx={{ color: "gold", fontSize: 15 }} />
                            <StarIcon sx={{ color: "gold", fontSize: 15 }} />
                            <StarIcon sx={{ color: "gold", fontSize: 15 }} />
                            <StarHalfIcon
                                sx={{ color: "gold", fontSize: 15 }}
                            />
                        </div>
                        <p className="header-top mt-1">
                            TOP RATING FROM OUR CLIENTS
                        </p>
                    </div>
                    <div className="d-flex align-items-center  me-2">
                        <div className="me-2">
                            <InsertEmoticonIcon
                                sx={{ color: "#f78da7", fontSize: 15 }}
                            />
                        </div>
                        <p className="header-top mt-1">
                            1.200+ HAPPY CUSTOMERS
                        </p>
                    </div>
                    <div className="d-flex align-items-center ">
                        <div className="me-2">
                            <LanguageIcon
                                sx={{ color: "#0693e3", fontSize: 15 }}
                            />
                        </div>
                        <p className="header-top mt-1">
                            SHIPPING FROM STOCK TO THE USA AND CANADA
                        </p>
                    </div>
                </div>
                <div className="d-flex align-items-center HeaderTopPart2">
                    <div className="d-flex pe-4">
                        <a
                            href="https://www.instagram.com/Glowmobilebox/"
                            className="pe-3"
                        >
                            <InstagramIcon
                                sx={{
                                    color: "#4E6AA4",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "35px",
                                    zIndex: "2",
                                }}
                            />
                        </a>
                        <a href="https://www.linkedin.com/company/mobile-light-box-us/">
                            <LinkedInIcon
                                sx={{
                                    color: "#4E6AA4",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "35px",
                                }}
                            />
                        </a>
                        <a style={{marginLeft:'15px', cursor:'pointer'}}>
                           <img src={TikTokIcon} style={{width:'20px'}} alt="" />
                        </a> 
                    </div>
                    <div className="header-top d-flex justify-centent-center">
                        <div className="d-flex justify-centent-center">
                            <PhoneAndroidIcon sx={{ fontSize: 15 }} />
                        </div>
                        <a
                            href="tel:+1 (404) 825-6116"
                            style={{
                                textDecoration: "none",
                                textTransform: "none",
                                color: "black",
                            }}
                            className="d-flex justify-content-center header-top"
                        >
                            +1 (404) 825-6116
                        </a>
                    </div>
                    <div className="d-flex">
                        <p className="header-top ps-2">SECURE PAYMENTS </p>
                    </div>
                    <div className="d-flex justify-centent-center">
                        <div className="d-flex justify-centent-center px-3">
                            <img src={Visa} alt="logo" width="40" />
                        </div>
                        <div className="d-flex justify-centent-center">
                            <img src={Paypal} alt="logo" width="50" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import TuneIcon from '@mui/icons-material/Tune'
import axios from 'axios'

export default function CategoriesLayout() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(null)

  const handleMenuClick = (event) => {
    event.stopPropagation()
    setIsMenuOpen(!isMenuOpen)
  }

  const handleCategoryClick = (categoryName) => {
    setActiveCategory((prevCategory) => {
      const newActiveCategory =
        prevCategory === categoryName ? null : categoryName
      console.log(`Active category will be set to:`, newActiveCategory)
      return newActiveCategory
    })
  }

  useEffect(() => {
    axios
      .get(`/api/getCategory`)
      .then((res) => {
        console.log('API Response:', res.data)
        if (res.status === 200 && res.data.categories) {
          setCategories(res.data.categories)
        } else {
          console.error(`Failed to fetch categories with status: ${res.status}`)
          setCategories([])
        }
      })
      .catch((error) => {
        console.error('Failed to fetch categories:', error)
        setCategories([])
      })

      console.log(`Active category has updated to: ${activeCategory}`);

  }, [activeCategory])

  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center firstSec'
        style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
      >
        <article>
          <div className='text-center '>
            <h3>Shop</h3>
          </div>
        </article>
      </section>
      <section className='shopSection d-flex justify-content-between'>
        <div className=' partCateg' style={{ width: '20vw' }}>
          <h3
            className='pt-4'
            style={{
              color: '#000',
              padding: '0 0 10px',
              background: '0 0',
              fontWeight: '500',
              fontSize: '20px',
              margin: '0 0 15px',
              position: 'relative',
              lineHeight: '20px',
              textTransform: 'uppercase',
              letterSpacing: '.1em',
            }}
          >
            Categories
          </h3>
          <div className='pt-3 d-flex row'></div>
          <div className='pt-3 d-flex row'>
            {categories.map((category) => (
              <div key={category.name}>
                <NavLink
                  to={`/category/${category.name.replace(/\s+/g, '')}`}
                  onClick={() => handleCategoryClick(category.name)}
                  className='d-flex justify-content-between'
                  style={{
                    color: '#828282',
                    textDecoration: 'none',
                    textTransform: 'none',
                  }}
                >
                  <p>{category.name}</p>
                  <div
                    style={{
                      backgroundColor: '#f1f1f1',
                      borderRadius: '35px',
                      textAlign: 'center',
                      width: '4vw',
                      height: '4vh',
                    }}
                  >
                    {category.productCount}
                  </div>
                </NavLink>
                {activeCategory === category.name && category.subCategories && (
                  <div className='sub-categories d-flex flex-column'>
                    {category.subCategories.map((subCategory, index) => {
                      console.log(
                        `Subcategory name: ${subCategory.nameSubCategory}`
                      )
                      return (
                        <NavLink
                          key={index}
                          to={`/${subCategory.nameSubCategory.replace(
                            /\s+/g,
                            ''
                          )}`}
                          className='sub-category-item'
                          style={{
                            marginLeft: '15px',
                            color: '#FFCB05',
                            marginBottom: '5px',
                            textDecoration: 'none',
                          }}
                        >
                          {subCategory.nameSubCategory}
                        </NavLink>
                      )
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className='partShopPage ms-4'>
          <div className='m-0 ps-4 d-flex justify-content-between'>
            <div className='navbarCategory'>
              <p
                className='pt-4 countProducts'
                style={{
                  color: '#828282',
                  textDecoration: 'none',
                  textTransform: 'none',
                }}
              >
                Showing 1–6 of 100 item(s)
              </p>
              <div>
                <div className='dropdown menu-icon ms-2'>
                  <button
                    className='dropdown-toggle'
                    onClick={handleMenuClick}
                    style={{
                      backgroundColor: '#FFCB05',
                      border: '0px',
                      width: '9vw',
                    }}
                  >
                    <TuneIcon style={{ color: 'white' }} />
                  </button>
                </div>
                <div
                  className={`dropdown-menu${isMenuOpen ? ' show' : ''}`}
                  aria-labelledby='dropdownMenuButton'
                >
                  {isMenuOpen &&
                    activeCategory &&
                    categories
                      .find((cat) => cat.name === activeCategory)
                      ?.subCategories.map((subCategory, index) => (
                        <NavLink
                          key={index}
                          to={`/${subCategory.nameSubCategory.replace(
                            /\s+/g,
                            ''
                          )}`}
                          className='d-flex justify-content-between'
                          style={{
                            color: '#828282',
                            textDecoration: 'none',
                            textTransform: 'none',
                          }}
                        >
                          <p>{subCategory.nameSubCategory}</p>
                        </NavLink>
                      ))}
                </div>
              </div>
            </div>
            <div className='dropdown align-self-end'>
              <button
                className='btn btn-secondary dropdown-toggle'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Default sorting
              </button>
              <ul className='dropdown-menu'>
                <li>
                  <a className='dropdown-item' href='#'>
                    Default Sorting
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='#'>
                    Sort By Popularity
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='#'>
                    Sort By Average Rating
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='#'>
                    Sort By Latest
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='#'>
                    Sort By Price: Low To High
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='#'>
                    Sort By Price: High To Low
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='grid-container'>
            <Outlet />
          </div>
        </div>
      </section>
    </>
  )
}

import { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
export default function EditCategory() {
  const { id } = useParams()
  const navigate = useNavigate();
  const [step, setStep] = useState(1)
  const [categoryInput, setCategory] = useState({
    name: '',
    description: '',
    subCategories: [{ nameSubCategory: '', descriptionSubCategory: '' }],
  })
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    message: '',
    severity: 'success',
  })

  const handleCategoryChange = (event) => {
    setCategory({
      ...categoryInput,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubCategoryChange = (index, event) => {
    const updatedSubCategories = [...categoryInput.subCategories]
    updatedSubCategories[index][event.target.name] = event.target.value
    setCategory({
      ...categoryInput,
      subCategories: updatedSubCategories,
    })
  }

  const addSubCategory = () => {
    setCategory({
      ...categoryInput,
      subCategories: [
        ...categoryInput.subCategories,
        { nameSubCategory: '', descriptionSubCategory: '' },
      ],
    })
  }

  const removeSubCategory = (index) => {
    const newSubCategories = [...categoryInput.subCategories]
    newSubCategories.splice(index, 1)
    setCategory({ ...categoryInput, subCategories: newSubCategories })
  }

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`/api/edit-category/${id}`)
        if (response.data.status === 200) {
          const { name, description, sub_categories } = response.data.category

          // Map sub_categories to match the frontend state structure
          const subCategories = sub_categories.map((sc) => ({
            nameSubCategory: sc.nameSubCategory,
            descriptionSubCategory: sc.descriptionSubCategory,
          }))

          setCategory({
            name,
            description,
            subCategories,
          })
        } else {
          console.error('Failed to fetch category data')
        }
      } catch (error) {
        console.error('Error fetching category data:', error)
      }
    }

    fetchCategoryData()
  }, [id])

  const updateCategory = (e) => {
    e.preventDefault();
    const data = categoryInput;
    axios.put(`/api/update-category/${id}`, data).then(res => {
      if (res.data.status === 200) {
        setAlertInfo({
          show: true,
          message: res.data.message,
          severity: 'success',
        })

        setTimeout(() => {
          setAlertInfo({ show: false, message: '', severity: '' })
        }, 3000)
        console.log('okay');
      }else {
        // Show error for server-side validation issues or other problems
        setAlertInfo({
          show: true,
          message: 'Error saving category',
          severity: 'error',
        })}
    });
  }

  return (
    <>
      <div className='container mt-5'>
        {alertInfo.show && (
          <div>
            <Alert
              icon={<CheckIcon fontSize='inherit' />}
              severity={alertInfo.severity}
            >
              {alertInfo.message}
            </Alert>
            <br />
          </div>
        )}
        <ul className='nav nav-tabs'>
          <li className='nav-item'>
            <button
              className={`nav-link ${step === 1 ? 'active' : ''}`}
              onClick={() => setStep(1)}
            >
              Category
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link ${step === 2 ? 'active' : ''}`}
              onClick={() => setStep(2)}
            >
              Sub Category
            </button>
          </li>
        </ul>
        <br />
        <form onSubmit={updateCategory}>
          {step === 1 && (
            <>
              <div style={{ width: '50vw' }}>
                <div className='form-group d-flex justify-content-between'>
                  <label htmlFor='categoryName'>Category Name</label>
                  <input
                    type='text'
                    className='form-control'
                    id='categoryName'
                    name='name'
                    value={categoryInput.name}
                    onChange={handleCategoryChange}
                    style={{ width: '35vw' }}
                  />
                </div>
                <br />
                <div className='form-group d-flex justify-content-between'>
                  <label htmlFor='description'>Description</label>
                  <textarea
                    className='form-control'
                    id='description'
                    name='description'
                    value={categoryInput.description}
                    onChange={handleCategoryChange}
                    style={{ width: '35vw' }}
                  />
                </div>
              </div>
              <br />
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => setStep(2)}
              >
                Next
              </button>
            </>
          )}

          {step === 2 && (
            <>
              {categoryInput.subCategories.map((subCategory, index) => (
                <div key={index} style={{ width: '55vw' }}>
                  <div className='form-group'>
                    <label>Sub Category Name</label>
                    <input
                      type='text'
                      className='form-control'
                      name='nameSubCategory'
                      value={subCategory.nameSubCategory}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Sub Category Description</label>
                    <textarea
                      className='form-control'
                      name='descriptionSubCategory'
                      value={subCategory.descriptionSubCategory}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                    />
                  </div>
                  {categoryInput.subCategories.length >= 1 && (
                    <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => removeSubCategory(index)}
                    >
                      Remove
                    </button>
                  )}
                  <hr />
                </div>
              ))}
              <button
                type='button'
                className='btn btn-secondary'
                onClick={addSubCategory}
              >
                Add Another Sub Category
              </button>
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
            </>
          )}
        </form>
      </div>
    </>
  )
}

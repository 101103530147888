import FirstImageNews from "../../../assets/img/bb5f8ca2-8e53-db9c-3f85-d37c855b2781.jpg";
import ImageDetailsNews from "../../../assets/img/bb5f8ca2-8e53-db9c-3f85-d37c855b2781.jpg";
import imageNews from "../../../assets/img/a879530c-5ca5-1fab-db6e-22162d3bff18.png";
export default function SevenNewsDetails() {
    return (
        <>
            <section
                className="d-flex firstSection justify-content-center align-items-center"
                style={{
                    height: "50vh",
                    width: "100vw",
                    backgroundImage: `url(${FirstImageNews})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></section>
            <section
                style={{ height: "210vh" }}
                className="d-flex firstSection justify-content-center pt-0"
            >
                <article
                    className="pb-3 pt-4"
                    style={{ width: "50vw", paddingLeft: "0" }}
                >
                    <div
                        style={{
                            borderBottom: "1px solid #c4c4c4",
                            width: "50vw",
                            height: "50vh",
                            paddingBottom: "20px",
                        }}
                    >
                        <p
                            style={{
                                color: "#EA4D2A",
                                padding: "0",
                                margin: "0",
                            }}
                        >
                            28 May 2022
                        </p>
                        <h1>PRODUCT OF THE YEAR 2022</h1>
                        <img
                            src={ImageDetailsNews}
                            alt="sectiom Image"
                            className="imageNews"
                        />
                        <div
                            style={{
                                width: "50vw",
                                height: "50vh",
                            }}
                            className="d-flex align-items-center justify-content-center"
                        >
                            <img src={imageNews} alt="sectiom Image" />
                        </div>
                        <p
                            style={{
                                padding: "10px 0",
                                margin: "0",
                                textTransform: "none",
                            }}
                        >
                            The “This is for You, World” campaign, is
                            introducing the new Mercedes-Benz EQS Sitting at the
                            top of the Mercedes-Benz EQ range, the new EQS is
                            the first all-electric luxury saloon offering, from
                            the premium German marque. Just as elegant and
                            classy as its S-class relative, the EQS successfully
                            stands out thanks to its fully-electric powertrain
                            and seamlessly futuristic design. Technology,
                            design, functionality and connectivity are the key
                            focus points of the executive-class EQS, which
                            manages to amalgamate these qualities to deliver a
                            delightful experience for the driver and passengers.
                            Achieving a drag coefficient (Cd) of just 0.202, the
                            EQS is the most aerodynamic production car in the
                            world, at the time of launch. We are proud that
                            Mercedes-Benz uses Glow Mobile Box for this
                            introduction. In this case the MLB 300 x 250 cm
                            double sided.
                        </p>
                        <img
                            src={FirstImageNews}
                            alt="sectiom Image"
                            className="imageNews pt-3 mb-3"
                        />
                        <a
                            href="/news"
                            style={{
                                textTransform: "none",
                                textDecoration: "none",
                                width: "8vw",
                                height: "4vh",
                                border: "1px solid #b8b8b8",
                                borderRadius: "5px",
                                color: "#b8b8b8",
                            }}
                            className="p-1"
                        >
                            Return to archive
                        </a>
                    </div>
                </article>
            </section>
        </>
    );
}

export default function OrderProcessing() {
  return (
    <>
      <div
        className='accordion'
        id='accordionExample'
        style={{ width: '85vw' }}
      >
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseOne'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              Rush Policy
            </button>
          </h2>
          <div
            id='collapseOne'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              We understand that on occasion something needs to be done quickly.
              For orders that need to be expedited please contact us via phone
              or e-mail. We offer a 2 Day Rush option for those situations.
              <ul>
                <li>2-Day Rush fee: 25% of the order total, $50 minimum.</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseTwo'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              Special request
            </button>
          </h2>
          <div
            id='collapseTwo'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              If you have any special requests or specific in-hand dates. Please
              mention those in the order notes or contact us at
              Samir@glowmobilebox.com
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

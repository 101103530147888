import FirstImageNews from '../../assets/img/news-1.png'
import SecondImageNews from '../../assets/img/news-2.jpg'
import ThirdImageNews from '../../assets/img/news-3.jpg'
import ForthImageNews from '../../assets/img/news-4.jpg'
import FiveImageNews from '../../assets/img/news-5.jpg'
import SixImageNews from '../../assets/img/video.jpg'
import SevenImageNews from '../../assets/img/bb5f8ca2-8e53-db9c-3f85-d37c855b2781.jpg'
import EightImageNews from '../../assets/img/fcdc269b-7a74-fa25-0f0f-0917872447f4-768x257.png'
import { Link } from 'react-router-dom'

export default function News() {
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center firstSecNews'
        style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
      >
        <article>
          <div className='text-center'>
            <h3>News</h3>
          </div>
        </article>
      </section>
      <section className='d-flex align-items-center justify-content-center flex-column'>
        <article className='pb-3 pt-4 newsArticle'>
          <div
            style={{
              borderBottom: '1px solid #c4c4c4',
              paddingBottom: '20px',
            }}
          >
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              28 May 2022
            </p>
            <h1>PRODUCT OF THE YEAR 2022</h1>
            <img
              src={FirstImageNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <p style={{ padding: '10px 0', margin: '0' }}>
              At PromZ.Live22 the inspiration event for Sales & Marketing we did
              show how easy it is to assemble the Glow Mobile Boxes, also simple
              to change the banners and last but not least, they all...
            </p>
            <Link
              to='/firstNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
        <article
          className='pb-3 pt-4 newsArticle'
          style={{
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div>
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              28 May 2022
            </p>
            <h1>LA NOUVELLE CLASSE C.</h1>
            <img
              src={SecondImageNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <p style={{ padding: '10px 0', margin: '0' }}>
              It takes only a banner change and the Mercedes-Benz campaign “La
              Nouvelle Classe C” is ready!So, it looks like the Glow Mobile
              Boxes make the new Elegant and Luxurious C-Class even more
              appealing.
            </p>
            <Link
              to='/twoNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
        <article
          className='pb-3 pt-4 newsArticle'
          style={{
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div>
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              27 May 2022
            </p>
            <h1>GLOW MOBILE BOX AT SHOE SHOW EU</h1>
            <img
              src={ThirdImageNews}
              alt='sectiom Image'
              style={{ width: '50vw', height: '110vh' }}
            />
            <p style={{ padding: '10px 0', margin: '0' }}>
              At PromZ.Live22 the inspiration event for Sales & Marketing we did
              show how easy it is to assemble the Glow Mobile Boxes, also simple
              to change the banners and last but not least, they all...
            </p>
            <Link
              to='/threeNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
        <article
          className='pb-3 pt-4 newsArticle'
          style={{
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div>
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              26 May 2022
            </p>
            <h1>WORLD #1 SURF TRAINER</h1>
            <img
              src={ForthImageNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <p style={{ padding: '10px 0', margin: '0' }}>
              WHY SmoothStar? Founded in 2004, SmoothStar is the number one surf
              trainer of choice for hundreds of thousands of surfers and surf
              coaches worldwide. Developed by experienced surfers, we are
              dedicated to the development and...
            </p>
            <Link
              to='/fourNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
        <article
          className='pb-3 pt-4 newsArticle'
          style={{
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div>
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              19 May 2022
            </p>
            <h1>GLOW MOBILE BOXES AND E-CARS EU</h1>
            <img
              src={FiveImageNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <p style={{ padding: '10px 0', margin: '0' }}>
              “Ready for Electric Love?” This is the question that Volkswagen
              asks us…..SEAT tells us “Plug in, Charge and Drive Emission
              Free.”“Get electrified and experience the fascination of our
              Plug-in hybride up close” according to AUDI...
            </p>
            <Link
              to='/fiveNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
        <article
          className='pb-3 pt-4 newsArticle'
          style={{
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div>
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              20 March 2022
            </p>
            <h1>SPRING, TIME TO GROW !!!</h1>
            <img src={SixImageNews} alt='sectiom Image' className='imageNews' />
            <p style={{ padding: '10px 0', margin: '0' }}>
              Matrix Frame USA was present at StorePoint Fashion last week.
              Matrix Frame EU will present Glow Mobile Box at the Sign&Print
              Expo on 22, 23 and 24 March, in Gorinchem and 25 March at
              PromZ,...
            </p>
            <Link
              to='/sixNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
        <article
          className='pb-3 pt-4 newsArticle'
          style={{
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div>
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              24 February 2022
            </p>
            <h1>THIS IS FOR YOU, WORLD</h1>
            <img
              src={SevenImageNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <p style={{ padding: '10px 0', margin: '0' }}>
              “Ready for Electric Love?” This is the question that Volkswagen
              asks us…..SEAT tells us “Plug in, Charge and Drive Emission
              Free.”“Get electrified and experience the fascination of our
              Plug-in hybride up close” according to AUDI...
            </p>
            <Link
              to='/sevenNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
        <article
          className='pb-3 pt-4 newsArticle'
          style={{
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div>
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              8 November 2021
            </p>
            <h1>FLIGHT SIMULATORS RE-INVENTED</h1>
            <img
              src={EightImageNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <p style={{ padding: '10px 0', margin: '0' }}>
              The Avion Full Flight Simulator is designed, developed and built
              in The Netherlands. Avion is a Dutch company with European
              partners. Just like the nature of our country, we are independent
              and innovative, this is...
            </p>
            <Link
              to='/eightNewsDetails'
              style={{
                color: '#FFCB05',
                fontSize: '18px',
                textTransform: 'none',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Read more
            </Link>
          </div>
        </article>
      </section>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'

export default function ViewProducts() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    message: '',
    severity: '',
  })
 // const apiUrl = 'http://127.0.0.1:8000/'
   const apiUrl = 'https://www.api.glowmobilebox.com/'

  useEffect(() => {
    axios.get('/api/view-products').then((res) => {
      if (res.status === 200) {
        setProducts(res.data.products)
      }
      setLoading(false)
    })
  }, [])

  const deleteProduct = (id) => {
    axios
      .delete(`/api/delete-product/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setAlertInfo({
            show: true,
            message: res.data.message,
            severity: 'success',
          })

          // Update the UI by filtering out the deleted product
          setProducts(products.filter((product) => product.id !== id))

          setTimeout(() => {
            setAlertInfo({ show: false, message: '', severity: '' })
          }, 3000)
        }
      })
      .catch((error) => {
        console.error('Deletion error:', error)
        setAlertInfo({
          show: true,
          message: 'Failed to delete product. Please try again.',
          severity: 'error',
        })
      })
  }

  if (loading) {
    return <h4>Loading Products...</h4>
  }

  return (
    <>
      <div>
        {alertInfo.show && (
          <Alert
            icon={<CheckIcon fontSize='inherit' />}
            severity={alertInfo.severity}
          >
            {alertInfo.message}
          </Alert>
        )}
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => navigate('/admin/add-product')} // Adjusted to add-product
        >
          Add Product
        </button>

        <div className='mt-4'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th>Product</th>
                <th>SKU</th>
                <th>Stock</th>
                <th>Category</th>
                <th>Sub-Category</th>
                <th>Selling Price</th>
                <th>Original Price</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td>
                    <img
                      src={`${apiUrl}storage/${product.image}`}
                      alt={product.name}
                      style={{ width: '50px', height: '50px' }}
                    />
                    {'  '}
                    {product.name}
                  </td>
                  <td>{product.sku}</td>
                  <td>{product.stock}</td>
                  <td>{product.category}</td>{' '}
                  {/* Assuming you have a 'category' field */}
                  <td>{product.subCategory || 'N/A'}</td>{' '}
                  {/* Adjust according to your data */}
                  <td>{product.sellingPrice}</td>
                  <td>{product.originalPrice}</td>
                  <td>
                    <Link to={`/admin/edit-product/${product.id}`}>
                      <EditIcon />
                    </Link>
                  </td>
                  <td>
                    <DeleteIcon
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => deleteProduct(product.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

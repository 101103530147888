import FirstImageNews from '../../../assets/img/video.jpg'
import ImageDetailsNews from '../../../assets/img/f3ee4b76-9b88-ab02-14b0-b2cf88d30debG.png'
import imageNews from '../../../assets/img/d516aca9-0003-be8f-79a6-c498dd1f6112.png'
export default function SixNewsDetails() {
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center'
        style={{
          height: '50vh',
          width: '100vw',
          backgroundImage: `url(${FirstImageNews})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></section>
      <section
        style={{ height: '200vh' }}
        className='d-flex firstSection justify-content-center pt-0'
      >
        <article
          className='pb-3 pt-4'
          style={{ width: '50vw', paddingLeft: '0' }}
        >
          <div
            style={{
              borderBottom: '1px solid #c4c4c4',
              width: '50vw',
              height: '50vh',
              paddingBottom: '20px',
            }}
          >
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              28 May 2022
            </p>
            <h1>PRODUCT OF THE YEAR 2022</h1>
            <img
              src={ImageDetailsNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <div
              style={{
                width: '50vw',
                height: '50vh',
              }}
              className='d-flex align-items-center justify-content-center'
            >
              <img src={imageNews} alt='sectiom Image' />
            </div>
            <p
              style={{
                padding: '10px 0',
                margin: '0',
                textTransform: 'none',
              }}
            >
              Matrix Frame USA was present at StorePoint Fashion last week.
              Matrix Frame EU will present Glow Mobile Box at the Sign&Print
              Expo on 22, 23 and 24 March, in Gorinchem and 25 March at PromZ,
              in Nieuwegein.
            </p>
            <img
              src={FirstImageNews}
              alt='sectiom Image'
              className='imageNews pt-3 mb-3'
            />
            <a
              href='/news'
              style={{
                textTransform: 'none',
                textDecoration: 'none',
                width: '8vw',
                height: '4vh',
                border: '1px solid #b8b8b8',
                borderRadius: '5px',
                color: '#b8b8b8',
              }}
              className='p-1'
            >
              Return to archive
            </a>
          </div>
        </article>
      </section>
    </>
  )
}

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Inventory2Icon from '@mui/icons-material/Inventory2'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import PlaceIcon from '@mui/icons-material/Place'
import PersonIcon from '@mui/icons-material/Person'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import LogoutIcon from '@mui/icons-material/Logout'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import HeaderTop from '../../layouts/frontend/HeaderTop'
import NavBar from '../../layouts/frontend/NavBar'
export default function DashboardClient() {
  const navigate = useNavigate()

  const logoutSubmit = (e) => {
    e.preventDefault()
    axios.post('/api/logout').then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('auth_username')
        navigate('/')
      }
    })
  }
  return (
    <>
      <main>
        <section
          className='d-flex firstSection justify-content-center mt-4'
          style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
        >
          <article>
            <div className='text-center'>
              <h3>MY ACCOUNT</h3>
            </div>
          </article>
        </section>
        <section className='d-flex' style={{ height: '100vh' }}>
          <aside style={{ padding: '5vh', width: '22vw', paddingRight: '0' }}>
            <h6>
              <b>MY ACCOUNT</b>
            </h6>
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <li style={{ marginTop: '8px' }}>
                <Link
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '18px',
                    color: '#000000',
                    cursor: 'pointer',
                    fontWeight: '600',
                  }}
                >
                  Dashboard
                </Link>
                <div
                  style={{
                    borderBottom: '1px solid #e7e7e7',
                    padding: '4px 0',
                    width: '15vw',
                  }}
                ></div>
              </li>
              <li style={{ marginTop: '8px' }}>
                <Link
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '18px',
                    color: '#e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  Orders
                </Link>
                <div
                  style={{
                    borderBottom: '1px solid #e7e7e7',
                    padding: '4px 0',
                    width: '15vw',
                  }}
                ></div>
              </li>
              <li style={{ marginTop: '5px' }}>
                <Link
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '18px',
                    color: '#e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  Invoices
                </Link>
                <div
                  style={{
                    borderBottom: '1px solid #e7e7e7',
                    padding: '4px 0',
                    width: '15vw',
                  }}
                ></div>{' '}
              </li>
              <li style={{ marginTop: '5px' }}>
                <Link
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '18px',
                    color: '#e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  Addresses
                </Link>
                <div
                  style={{
                    borderBottom: '1px solid #e7e7e7',
                    padding: '4px 0',
                    width: '15vw',
                  }}
                ></div>
              </li>
              <li style={{ marginTop: '5px' }}>
                <Link
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '18px',
                    color: '#e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  Payment methods
                </Link>
                <div
                  style={{
                    borderBottom: '1px solid #e7e7e7',
                    padding: '4px 0',
                    width: '15vw',
                  }}
                ></div>
              </li>
              <li style={{ marginTop: '5px' }}>
                <Link
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '18px',
                    color: '#e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  Account details
                </Link>
                <div
                  style={{
                    borderBottom: '1px solid #e7e7e7',
                    padding: '4px 0',
                    width: '15vw',
                  }}
                ></div>
              </li>
              <li style={{ marginTop: '5px' }}>
                <Link
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '18px',
                    color: '#e7e7e7',
                    cursor: 'pointer',
                  }}
                  onClick={logoutSubmit}
                >
                  Log out
                </Link>
                <div
                  style={{
                    borderBottom: '1px solid #e7e7e7',
                    padding: '4px 0',
                    width: '15vw',
                  }}
                ></div>
              </li>
            </ul>
          </aside>
          <article style={{ width: '70vw', paddingLeft: '0' }}>
            <p>
              Hello 
              <span style={{ color: '#FFCB05' }}>Logout</span>)
            </p>
            <p>
              From your account dashboard you can view your{' '}
              <span style={{ color: '#FFCB05' }}>recent orders</span>, manage
              your{' '}
              <span style={{ color: '#FFCB05' }}>
                shipping and billing addresses
              </span>
              , and
              <span style={{ color: '#FFCB05' }}>
                edit your password and account details.
              </span>
            </p>
            <div className='d-flex'>
              <div
                style={{
                  height: '38vh',
                  width: '23vw',
                  border: '1px solid #e7e7e7',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '2vw',
                }}
              >
                <a
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '23vw', // Take the full width of the parent
                    height: '20vh', // Take the full height of the parent
                    textTransform: 'none',
                    textDecoration: 'none',
                    border: '4px solid #e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  <Inventory2Icon sx={{ fontSize: '45px', color: '#c4c1c1' }} />
                  <p style={{ margin: 0, paddingTop: '10px' }}>ORDERS</p>
                </a>
              </div>
              <div
                style={{
                  height: '38vh',
                  width: '23vw',
                  border: '1px solid #e7e7e7',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '2vw',
                }}
              >
                <a
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '23vw', // Take the full width of the parent
                    height: '20vh', // Take the full height of the parent
                    textTransform: 'none',
                    textDecoration: 'none',
                    border: '4px solid #e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  <CloudDownloadIcon
                    sx={{ fontSize: '45px', color: '#c4c1c1' }}
                  />
                  <p style={{ margin: 0, paddingTop: '10px' }}>Invoices</p>
                </a>
              </div>
              <div
                style={{
                  height: '38vh',
                  width: '23vw',
                  border: '1px solid #e7e7e7',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <a
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '23vw', // Take the full width of the parent
                    height: '20vh', // Take the full height of the parent
                    textTransform: 'none',
                    textDecoration: 'none',
                    border: '4px solid #e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  <PlaceIcon sx={{ fontSize: '45px', color: '#c4c1c1' }} />
                  <p style={{ margin: 0, paddingTop: '10px' }}>Addresses</p>
                </a>
              </div>
            </div>
            <div className='d-flex'>
              <div
                style={{
                  height: '38vh',
                  width: '23vw',
                  border: '1px solid #e7e7e7',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '2vw',
                }}
              >
                <a
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '23vw', // Take the full width of the parent
                    height: '20vh', // Take the full height of the parent
                    textTransform: 'none',
                    textDecoration: 'none',
                    border: '4px solid #e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  <PersonIcon sx={{ fontSize: '45px', color: '#c4c1c1' }} />
                  <p style={{ margin: 0, paddingTop: '10px' }}>Account details</p>
                </a>
              </div>
              <div
                style={{
                  height: '38vh',
                  width: '23vw',
                  border: '1px solid #e7e7e7',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '2vw',
                }}
              >
                <a
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '23vw', // Take the full width of the parent
                    height: '20vh', // Take the full height of the parent
                    textTransform: 'none',
                    textDecoration: 'none',
                    border: '4px solid #e7e7e7',
                    cursor: 'pointer',
                  }}
                >
                  <FavoriteBorderIcon
                    sx={{ fontSize: '45px', color: '#c4c1c1' }}
                  />
                  <p style={{ margin: 0, paddingTop: '10px' }}>Wich list</p>
                </a>
              </div>
              <div
                style={{
                  height: '38vh',
                  width: '23vw',
                  border: '1px solid #e7e7e7',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <button
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '23vw',
                    height: '20vh',
                    textTransform: 'none',
                    textDecoration: 'none',
                    border: '4px solid #e7e7e7',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                  }}
                  onClick={logoutSubmit}
                >
                  <LogoutIcon sx={{ fontSize: '45px', color: '#c4c1c1' }} />
                  <p style={{ margin: 0, paddingTop: '10px' }}>LOGOUT</p>
                </button>
              </div>
            </div>
          </article>
        </section>
      </main>
    </>
  )
}

import Link from '@mui/material/Link'
import ShareIcon from '@mui/icons-material/Share'
import FacebookIcon from '@mui/icons-material/Facebook'
import XIcon from '@mui/icons-material/X'
import GoogleIcon from '@mui/icons-material/Google'
import EmailIcon from '@mui/icons-material/Email'
import { NavLink, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import HeaderTop from '../../../layouts/frontend/HeaderTop'
import NavBar from '../../../layouts/frontend/NavBar'

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

export default function Login() {
  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem('auth_token')) {
      navigate('/dashboard-client')
    }
  })

  const [loginInput, setLogin] = useState({
    email: '',
    password: '',
    error_list: [],
  })

  const handleInput = (e) => {
    e.persist()
    setLogin({ ...loginInput, [e.target.name]: e.target.value })
  }

  const loginSubmit = (e) => {
    e.preventDefault()

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    }

    axios.get('/sanctum/csrf-cookie').then((response) => {
      console.log(response)
      axios.post(`api/login`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          localStorage.setItem('auth_username', res.data.username)
          localStorage.setItem('role_as', res.data.role_as.toString()); // Assuming `role_as` is either 1 or 0

          const userRole = res.data.role_as 
          if (userRole === 1) {
            navigate('/admin/dashboard')
          } else if (userRole === 0){
            navigate('/dashboard-client')
          }
        } else if (res.data.status === 401) {
          // Handle unauthorized or login failure
        } else {
          setLogin({
            ...loginInput,
            error_list: res.data.validation_errors,
          })
        }
      })
    })
  }
  return (
    <>
      <main>
        <section
          className='d-flex firstSection justify-content-center align-items-center'
          style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
        >
          <article>
            <div className='text-center'>
              <h3>MY ACCOUNT</h3>
            </div>
          </article>
        </section>
        <section
          style={{
            paddingTop: '6vh',
            paddingLeft: '8vw',
            height: '100vh',
          }}
        >
          <article
            style={{
              width: '80vw',
              height: '90vh',
              border: '1px solid #c4c4c4',
              paddingTop: '10vh',
              paddingLeft: '3vw',
              paddingRight: '10vw',
            }}
          >
            <h6 style={{ color: '#FFCB05', marginBottom: '5vh' }}>LOGIN</h6>
            <form onSubmit={loginSubmit}>
              <div className='mb-3'>
                <label
                  htmlFor='exampleInputEmail1'
                  className='form-label'
                  style={{ fontWeight: '500', fontSize: '20px' }}
                >
                  Username or email address{' '}
                  <span style={{ color: '#c10000' }}>*</span>
                </label>
                <input
                  type='email'
                  onChange={handleInput}
                  value={handleInput.email}
                  className='form-control'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  name='email'
                />
                <span>{loginInput.error_list.email}</span>
              </div>
              <div className='mb-4 pb-4'>
                <label
                  htmlFor='exampleInputPassword1'
                  className='form-label'
                  style={{ fontWeight: '500', fontSize: '20px' }}
                >
                  Password <span style={{ color: '#c10000' }}>*</span>
                </label>
                <input
                  type='password'
                  onChange={handleInput}
                  value={handleInput.password}
                  className='form-control'
                  id='exampleInputPassword1'
                  name='password'
                />
                <span>{loginInput.error_list.password}</span>
              </div>

              <div className='d-flex align-items-center'>
                <button
                  type='submit'
                  className='btn btn-primary me-3'
                  style={{
                    color: 'white',
                    backgroundColor: '#FFCB05',
                    border: 'none',
                    borderRadius: '35px',
                    width: '10vw',
                    height: '8vh',
                  }}
                  onClick={loginSubmit}
                >
                  Login
                </button>
                <div className='mb-3 form-check align-self-end'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='exampleCheck1'
                  />
                  <label
                    className='form-check-label'
                    htmlFor='exampleCheck1'
                    style={{
                      fontWeight: '500',
                      color: '#000000',
                    }}
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <div className='mt-3 pt-3'>
                <Link
                  style={{
                    color: '#FFCB05',
                    textDecoration: 'none',
                    textTransform: 'none',
                    fontWeight: '500',
                    fontSize: '18px',
                    cursor: 'pointer',
                  }}
                >
                  Lost your password?
                </Link>
              </div>

              <div className='mt-3 pt-3'>
                <NavLink
                  style={{
                    color: '#000000',
                    textDecoration: 'none',
                    textTransform: 'none',
                    fontWeight: '500',
                    fontSize: '18px',
                    cursor: 'pointer',
                  }}
                  to='/register'
                >
                  CREATE ACCOUNT
                </NavLink>
              </div>
            </form>
          </article>
        </section>
        <section
          className='d-flex'
          style={{
            paddingTop: '5vh',
            paddingBottom: '5vh',
            width: '100vw',
            paddingLeft: '8vw',
          }}
        >
          <div className='pe-3'>
            <ShareIcon />
          </div>
          <p style={{ fontSize: '20px', paddingRight: '10px' }}>
            Share this post
          </p>
          <div className='d-flex'>
            <div>
              <FacebookIcon sx={{ color: '#4054B2', fontSize: 30 }} />
            </div>
            <div>
              <XIcon sx={{ color: '#6EC1E4', fontSize: 30 }} />
            </div>
            <div>
              <GoogleIcon sx={{ color: '#cf2e2e', fontSize: 30 }} />
            </div>
            <div>
              <EmailIcon sx={{ color: '#cf2e2e', fontSize: 30 }} />
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default function BlogPress() {
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center'
        style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
      >
        <article>
          <div className='text-center'>
            <h3>Blog Press</h3>
          </div>
        </article>
      </section>
      <section
        style={{
          paddingTop: '5vh',
          paddingBottom: '5vh',
          width: '100vw',
          paddingLeft: '5vw',
        }}
      >
        <div style={{ borderBottom: '1px solid #c4c4c4', width: '80vw' }}>
          <h4>
            Glow Mobile Box: The Best Choice for Backlit Banner Frames in a
            popup Retail environment
          </h4>
          <p>
            When it comes to creating eye-catching and memorable retail
            displays, backlit banners are a powerful tool. However, choosing the
            right frame for your backlit banners is equally crucial. Among the
            various options available, the Glow Mobile Box stands out as the
            best choice for a multitude of reasons. In this...
          </p>
        </div>
        <div
          className='d-flex justify-content-between mt-4'
          style={{ width: '50vw' }}
        >
          <p style={{ fontSize: '12px', color: '#949494' }}>
            Posted By <span style={{ color: '#FFCB05' }}>Borja</span>
          </p>
          <p style={{ fontSize: '12px', color: '#949494' }}>
            Category: <span style={{ color: '#FFCB05' }}>General</span>
          </p>
          <p style={{ fontSize: '12px', color: '#949494' }}>
            Comments: <span style={{ color: '#FFCB05' }}>0</span>
          </p>

          <p style={{ fontSize: '12px', color: '#949494' }}>
            Post Date: <spa style={{ color: '#FFCB05' }}>7 September 2023</spa>
          </p>
        </div>
        <div className='mt-3'>
          <button
            style={{
              color: '#FFCB05',
              backgroundColor: '#ffffff',
              borderRadius: '30px',
              borderColor: '#FFCB05',
              padding: '10px',
              fontSize: '20px',
            }}
          >
            Read More
          </button>
        </div>
      </section>
    </>
  )
}

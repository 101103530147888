export default function Artwork() {
  return (
    <>
      <div
        className='accordion'
        id='accordionExample'
        style={{ width: '85vw' }}
      >
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseOne'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              How do I submit artwork files
            </button>
          </h2>
          <div
            id='collapseOne'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              You can submit your print-ready files either via e-mail
              Samir@glowmobilebox.com or via our WeTransfer site:
              https://glowmobilebox.com/ Please mention your web shop order
              number when you are sending the print files.For further
              information see you print instructions page: Print Instructions –
              Glow Mobile Box
            </div>
          </div>
        </div>
        <div
          className='accordion-item'
          style={{
            borderRadius: '0',
            marginBottom: '10px',
          }}
        >
          <h2 className='accordion-header'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseTwo'
              aria-expanded='true'
              aria-controls='collapseOne'
              style={{
                color: '#FFCB05',
                fontWeight: '500',
                borderLeft: '4px solid #FFCB05',
                borderRadius: '0',
                backgroundColor: '#f7f7f7',
              }}
            >
              What type of artwork files do you accept?
            </button>
          </h2>
          <div
            id='collapseTwo'
            className='accordion-collapse collapse show'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              We prefer print-ready files to be submitted in PDF or TIFF format.
              Though we can accept the following file formats.
              <ul>
                <li>InDesign (.indd)</li>
                <li>Illustrator (.ai)</li>
                <li>Photoshop (.psd)</li>
                <li>Encapsulated PostScript (.eps)</li>
                <li>Portable Document Format (.pdf)</li>
                <li>Tagged Image File Format (.tiff)</li>
              </ul>
              Please consult our print instructions page for all details: Print
              Instructions – Glow Mobile Box
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

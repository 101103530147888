export default function TradeShow() {
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center tradeShowSection'
        style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
      >
        <article>
          <div className='text-center'>
            <h3>Trade Show</h3>
          </div>
        </article>
      </section>
      <section
        style={{ paddingLeft: '10vw', paddingTop: '5vh', paddingBottom: '5vh' }}
      >
        <h1>
          Our upcoming <b>Shows</b>
        </h1>
        <p>
          Join us at upcoming events and witness the brilliance of our products
          firsthand. We look forward to sharing our innovations with you!
        </p>
        <div style={{ paddingTop: '5vh' }}>
          <p>
            <b>
              Apologies, but no results were found for the requested archive.
            </b>
          </p>
        </div>
      </section>
    </>
  )
}

import { useEffect, useState } from 'react'
import axios from 'axios'
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'

export default function Product() {
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    message: '',
    severity: '',
  })
  const [imagePreview, setImagePreview] = useState(null)
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState([])
  const [images, setImages] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [productInput, setProductInput] = useState({
    name: '',
    sku: '',
    stock: '',
    category_id: '',
    sub_category_id: '',
    description: '',
    sellingPrice: '',
    originalPrice: '',
  })

  useEffect(() => {
    axios
      .get(`/api/all-category`)
      .then((res) => {
        if (res.data.status === 200) {
          setCategoryList(res.data.categories)
        }
      })
      .catch((error) => {
        console.error('Failed to fetch categories', error)
      })
  }, [])

  const handleInput = (e) => {
    const { name, value } = e.target
    setProductInput({ ...productInput, [name]: value })

    if (name === 'category_id') {
      const category = categoryList.find((cat) => cat.id.toString() === value)
      setSelectedCategory(category || null)
    }
  }

  const handleImageChange = (e) => {
    const newFiles = Array.from(e.target.files)
    const updatedFiles = [...images, ...newFiles]
    setImages(updatedFiles)

    const updatedPreviews = updatedFiles.map((file) =>
      URL.createObjectURL(file)
    )

    if (updatedFiles.length > 0) {
      setImagePreview(updatedPreviews[0])
      setAdditionalImagePreviews(updatedPreviews.slice(1))
    }
  }

  const submitProduct = (e) => {
    e.preventDefault()

    const formData = new FormData()
    Object.keys(productInput).forEach((key) => {
      if (key === 'sub_category_id' && productInput[key] === '') {
        // Skip adding sub_category_id to formData if empty
      } else {
        formData.append(key, productInput[key])
      }
      if (key === 'sellingPrice' && productInput[key] === '') {
        // Don't append sellingPrice if it's empty
    } else {
        formData.append(key, productInput[key]);
    }
    })

    images.forEach((image, index) => {
      const formKey = index === 0 ? 'image' : `additional_images[${index - 1}]`
      formData.append(formKey, image)
    })
    // formData.append(
    //   'sub_category_id',
    //   parseInt(productInput.sub_category_id, 10)
    // )

    axios
      .post(`/api/store-product`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.message)
          setProductInput({
            name: '',
            sku: '',
            stock: '',
            category_id: '',
            sub_category_id: '',
            description: '',
            sellingPrice: '',
            originalPrice: '',
          })
          setImagePreview(null)
          setAdditionalImagePreviews([])
          setImages([])
          setAlertInfo({
            show: true,
            message: res.data.message,
            severity: 'success',
          })

          setTimeout(() => {
            setAlertInfo({ show: false, message: '', severity: '' })
          }, 3000)
        }
      })
      .catch((error) => console.error('Error uploading product:', error))
  }
  return (
    <>
    {alertInfo.show && (
        <div>
          <Alert
            icon={<CheckIcon fontSize='inherit' />}
            severity={alertInfo.severity}
          >
            {alertInfo.message}
          </Alert>
          <br />
        </div>
      )}
      <h4>Add Product</h4>
      <div>
        <div>
          <form className='row g-3' onSubmit={submitProduct}>
            <div className='col-md-6'>
              <label className='form-label'>Product name *</label>
              <input
                type='text'
                className='form-control'
                name='name'
                onChange={handleInput}
                value={productInput.name}
              />
            </div>
            <div className='col-md-6'>
              <label className='form-label'>SKU *</label>
              <input
                type='text'
                className='form-control'
                name='sku'
                onChange={handleInput}
                value={productInput.sku}
              />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Stock</label>
              <input
                type='number'
                className='form-control'
                name='stock'
                onChange={handleInput}
                value={productInput.stock}
              />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Category</label>
              <select
                className='form-select'
                name='category_id'
                onChange={handleInput}
                value={productInput.category_id}
              >
                <option>Choose Category</option>
                {categoryList.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Sub Category</label>
              <select
                className='form-select'
                name='sub_category_id'
                onChange={handleInput}
                value={productInput.sub_category_id}
              >
                <option>Choose Sub Category</option>
                {selectedCategory?.sub_categories.map((subCat) => (
                  <option key={subCat.id} value={subCat.id}>
                    {subCat.nameSubCategory}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12'>
              <label className='form-label'>Description</label>
              <textarea
                type='text'
                className='form-control'
                name='description'
                onChange={handleInput}
                value={productInput.description}
              />
            </div>
            <div className='col-md-6'>
              <label className='form-label'>Selling Price</label>
              <input
                type='text'
                className='form-control'
                name='sellingPrice'
                onChange={handleInput}
                value={productInput.sellingPrice}
              />
            </div>
            <div className='col-md-6'>
              <label className='form-label'>Original Price</label>
              <input
                type='text'
                className='form-control'
                name='originalPrice'
                onChange={handleInput}
                value={productInput.originalPrice}
              />
            </div>
            <div className='col-md-6'>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt='Preview'
                  style={{
                    maxWidth: '200px',
                    maxHeight: '200px',
                    objectFit: 'cover', // Ensure the aspect ratio is maintained without distortion
                    marginBottom: '10px',
                  }}
                />
              )}
              {additionalImagePreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`Additional Preview ${index}`}
                  style={{
                    maxWidth: '200px', // Match the size of the main image preview
                    maxHeight: '200px', // Match the size of the main image preview
                    objectFit: 'cover', // Ensure the aspect ratio is maintained without distortion
                    marginRight: '5px',
                  }}
                />
              ))}
              <input
                type='file'
                accept='image/*'
                onChange={handleImageChange}
                multiple
                id='imageUpload'
                style={{ display: 'none' }}
              />
              <label
                htmlFor='imageUpload'
                style={{
                  cursor: 'pointer',
                  background: '#007bff',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  display: 'block', // Ensure the label is block-level to better control layout
                  marginTop: '10px', // Add some space above the upload button
                }}
              >
                Upload Images
              </label>
            </div>

            <div className='col-12'>
              <button type='submit' className='btn btn-primary'>
                Add Product
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

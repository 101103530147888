import FirstImageNews from '../../../assets/img/news-4.jpg'
import ImageDetailsNews from '../../../assets/img/news-4.jpg'
import imageNews from '../../../assets/img/9f3abe6f-d708-16c5-1afb-243ff3e3066a.png'
export default function FourNewsDetails() {
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center'
        style={{
          height: '50vh',
          width: '100vw',
          backgroundImage: `url(${FirstImageNews})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></section>
      <section
        style={{ height: '200vh' }}
        className='d-flex firstSection justify-content-center pt-0'
      >
        <article
          className='pb-3 pt-4'
          style={{ width: '50vw', paddingLeft: '0' }}
        >
          <div
            style={{
              borderBottom: '1px solid #c4c4c4',
              width: '50vw',
              height: '50vh',
              paddingBottom: '20px',
            }}
          >
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              28 May 2022
            </p>
            <h1>PRODUCT OF THE YEAR 2022</h1>
            <img
              src={ImageDetailsNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <div
              style={{
                width: '50vw',
                height: '50vh',
              }}
              className='d-flex align-items-center justify-content-center'
            >
              <img src={imageNews} alt='sectiom Image' />
            </div>
            <p
              style={{
                padding: '10px 0',
                margin: '0',
                textTransform: 'none',
              }}
            >
              At PromZ.Live22 the inspiration event for Sales & Marketing we did
              show how easy it is to assemble the Glow Mobile Boxes, also simple
              to change the banners and last but not least, they all come in a
              handy Wheely Bag. Glow Mobile Box Battery was awarded not only the
              Jury Prize, but also the Audience Award of the PromZ Promotional
              Product of the Year 2022. We are very proud to bring home two
              prizes! You can order Glow Mobile Boxes at www.glowmobilebox.com
            </p>
            <img
              src={FirstImageNews}
              alt='sectiom Image'
              className='imageNews pt-3 mb-3'
            />
            <a
              href='/news'
              style={{
                textTransform: 'none',
                textDecoration: 'none',
                width: '8vw',
                height: '4vh',
                border: '1px solid #b8b8b8',
                borderRadius: '5px',
                color: '#b8b8b8',
              }}
              className='p-1'
            >
              Return to archive
            </a>
          </div>
        </article>
      </section>
    </>
  )
}

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function MyPagination({ count, page, onChange }) {
    return (
        <Stack spacing={2}>
            <Pagination
                count={count}
                page={page}
                onChange={onChange}
                variant="outlined"
                shape="rounded"
                className="pagination" // Bootstrap class for additional styling
            />
        </Stack>
    );
}

import { Outlet } from 'react-router-dom'
// import '../index.css'
import { NavLink } from 'react-router-dom'

export default function FaqLayout() {
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center firstSec'
        style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
      >
        <article>
          <div className='text-center'>
            <h3>FAQ</h3>
          </div>
        </article>
      </section>
      <section className='faq' style={{ height: 'auto', paddingLeft: '5vw' }}>
        <h1>Frequently Asked Questions</h1>
        <ul className='nav nav-pills'>
          <li className='nav-item'>
            <NavLink
              to='/faq'
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: '#FFCB05',
                      color: '#ffffff',
                    }
                  : { color: '#FFCB05' }
              }
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              Show All
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/artwork'
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: '#FFCB05',
                      color: '#ffffff',
                    }
                  : { color: '#FFCB05' }
              }
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              Artwork
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/banners'
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: '#FFCB05',
                      color: '#ffffff',
                    }
                  : { color: '#FFCB05' }
              }
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              Banners
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/general'
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: '#FFCB05',
                      color: '#ffffff',
                    }
                  : { color: '#FFCB05' }
              }
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              General
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/Miscellaneous'
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: '#FFCB05',
                      color: '#ffffff',
                    }
                  : { color: '#FFCB05' }
              }
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              Miscellaneous
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/glowmobilebox'
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: '#FFCB05',
                      color: '#ffffff',
                    }
                  : { color: '#FFCB05' }
              }
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              Glow Mobile Box
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/orderprocessing'
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: '#FFCB05',
                      color: '#ffffff',
                    }
                  : { color: '#FFCB05' }
              }
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              Order processing
            </NavLink>
          </li>
        </ul>
        <div style={{ width: '85vw' }}>
          <hr />
        </div>
        <Outlet />
      </section>
    </>
  )
}

import Navbar from '../../layouts/frontend/NavBar'
import HeaderTop from '../../layouts/frontend/HeaderTop'
export default function Page403() {
  return (
    <>
      <header>
        <HeaderTop />
        <Navbar />
      </header>
      <main>
      <section
          className='d-flex firstSection justify-content-center mt-4'
          style={{ backgroundColor: '#f7f7f7', height: '40vh' }}
        >
          <article>
            <div className='text-center'>
              <h3>Page 403</h3>
            </div>
          </article>
        </section>
      </main>
    </>
  )
}

import Document from "../../assets/img/document.png";
import DescriptionIcon from "@mui/icons-material/Description";
import ShareIcon from "@mui/icons-material/Share";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import page1 from "../../assets/print instruction/print instruction_page-0001.pdf";
import page2 from "../../assets/print instruction/print instruction_page-0002.pdf";
import page3 from "../../assets/print instruction/print instruction_page-0003.pdf";
import page4 from "../../assets/print instruction/print instruction_page-0004.pdf";
import page5 from "../../assets/print instruction/print instruction_page-0005.pdf";
import page6 from "../../assets/print instruction/print instruction_page-0006.pdf";
import page7 from "../../assets/print instruction/print instruction_page-0007.pdf";
import page8 from "../../assets/print instruction/print instruction_page-0008.pdf";
import page9 from "../../assets/print instruction/print instruction_page-0009.pdf";
import page10 from "../../assets/print instruction/print instruction_page-0010.pdf";
import page11 from "../../assets/print instruction/print instruction_page-0011.pdf";
import page12 from "../../assets/print instruction/print instruction_page-0012.pdf";
import page13 from "../../assets/print instruction/print instruction_page-0013.pdf";
import page14 from "../../assets/print instruction/print instruction_page-0014.pdf";
import page15 from "../../assets/print instruction/print instruction_page-0015.pdf";
import page16 from "../../assets/print instruction/print instruction_page-0016.pdf";
import page17 from "../../assets/print instruction/print instruction_page-0017.pdf";
import page18 from "../../assets/print instruction/print instruction_page-0018.pdf";
import page19 from "../../assets/print instruction/print instruction_page-0019.pdf";
import page20 from "../../assets/print instruction/print instruction_page-0020.pdf";

export default function PrintInstruction() {
    return (
        <>
            <section
                className="d-flex firstSection firstprint justify-content-center align-items-center"
                style={{ backgroundColor: "#f7f7f7", height: "40vh" }}
            >
                <article>
                    <div className="text-center">
                        <h3>Print Instruction</h3>
                    </div>
                </article>
            </section>
            <section className="printInstruction">
                <p style={{ width: "80vw" }}>
                    For print orders, please follow these instructions. Share
                    the below details with your designer or studio, so we can
                    properly process your print order. After placing your for
                    the Glow Mobile Box with printing included, we’ll
                    automatically send you a follow-up message containing PDF
                    instructions for your specific size of Glow Mobile Box.
                </p>
                <div className="firstPrint d-flex">
                    <div className="firstPrintDiv">
                        <h3 style={{ color: "black" }}>
                            READY TO SEND OVER YOUR DESIGNS?
                        </h3>
                        <p>
                            Print-ready files must be sent to
                            <b style={{ color: "#FFCB05" }}>
                                {" "}
                                Samir@glowmobilebox.com
                            </b>
                        </p>
                        <p>
                            Please mention your webshop order number when you
                            are sending the print files.
                        </p>
                        <h3 style={{ color: "black" }}>
                            DOWNLOAD YOUR PRINT TEMPLATE INSTRUCTIONS HERE
                        </h3>
                        <p>
                            To help set up the right print files, we’ve setup an
                            instructional template specifically for each
                            product. Please download the file for you and/or
                            your creative team. It will help you set up the
                            design to be printed in the correct way to be
                            printed, with as little hassle as possible. Please
                            download the print instructions for your specific
                            product.
                        </p>
                    </div>
                    <div
                        style={{
                            color: "#7A7A7A",
                            backgroundColor: "#F7F7F7",
                        }}
                        className="quickSammary"
                    >
                        <div className="p-4 quickSammary">
                            <h5>
                                <b>QUICK SUMMARY</b>
                            </h5>
                            <ul>
                                <li>
                                    File format: PDF (version 1.6 or higher) or
                                    TIF (LZW compression)
                                </li>
                                <li>
                                    Fonts converted to paths or fully embedded
                                </li>
                                <li>Bleed: 10 mm</li>
                                <li>Safety distance: 30 mm</li>
                                <li>
                                    Minimum resolution: 100 ppi (for scale 1 to
                                    1)
                                </li>
                                <li>
                                    Remove crops and all extra markings before
                                    saving file
                                </li>
                                <li>
                                    No alpha channels, masks, color bars, etc.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 85×200 Battery</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page1}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>Glow Mobile Box 100×200 (80 BIPS)</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page2}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 85×150</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page3}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 85×200</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page4}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 85×225</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page5}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 85×240</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page6}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 85×250</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page7}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 100×150</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page8}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 100×200</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page9}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 100×225</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page10}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 100×240</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page11}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 100×250</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page12}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 50×150 Battery</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page13}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 200×200</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page14}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 200×200</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page14}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 100×100 Counter Battery</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page15}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 200×225 Counter Battery</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page16}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 100×200 Battery</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page17}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 200×200</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page18}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 200×250</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page19}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    className="d-flex mt-2 ps-3"
                    style={{ borderBottom: "2px solid #7A7A7A", width: "70vw" }}
                >
                    <img src={Document} alt="Document" width="35" height="50" />
                    <div className="ps-3 align-self-center">
                        <h6>
                            <b>MLB 50×150D</b>
                        </h6>
                        <div className="d-flex">
                            <p className=" align-self-end">
                                Download instructions
                            </p>
                            <a
                                className="btnSection align-self-start ms-2 ps-1"
                                href={page20}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DescriptionIcon
                                    sx={{ fontSize: 20, color: "black" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="d-flex"
                style={{
                    paddingTop: "5vh",
                    paddingBottom: "5vh",
                    width: "100vw",
                    paddingLeft: "5vw",
                }}
            >
                <div className="pe-3">
                    <ShareIcon />
                </div>
                <p style={{ fontSize: "20px", paddingRight: "10px" }}>
                    Share this post
                </p>
                <div className="d-flex">
                    <div>
                        <FacebookIcon sx={{ color: "#4054B2", fontSize: 30 }} />
                    </div>
                    <div>
                        <XIcon sx={{ color: "#6EC1E4", fontSize: 30 }} />
                    </div>
                    <div>
                        <GoogleIcon sx={{ color: "#cf2e2e", fontSize: 30 }} />
                    </div>
                    <div>
                        <EmailIcon sx={{ color: "#cf2e2e", fontSize: 30 }} />
                    </div>
                </div>
            </section>
        </>
    );
}

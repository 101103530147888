import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
export default function Sidebar() {
  const navigate = useNavigate()
  const logoutSubmit = (e) => {
    e.preventDefault()
    axios.post('/api/logout').then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('auth_username')
        navigate('/')
      }
    })
  }
  const [activeIndex, setActiveIndex] = useState(0)
  const [isSidebarHidden, setIsSidebarHidden] = useState(
    window.innerWidth < 768
  )

  const menuItems = [
    { icon: 'bxs-dashboard', text: 'Dashboard', path: '/admin/dashboard' },
    {
      icon: 'bxs-shopping-bag-alt',
      text: 'My Category',
      path: '/admin/category',
    },
    {
      icon: 'bxs-shopping-bag-alt',
      text: 'View Category',
      path: '/admin/view-category',
    },
    {
      icon: 'bxs-doughnut-chart',
      text: 'Products',
      path: '/admin/add-product',
    },
    {
      icon: 'bxs-shopping-bag-alt',
      text: 'View Products',
      path: '/admin/view-products',
    },
    { icon: 'bxs-message-dots', text: 'Orders', path: '/admin/orders' },
    { icon: 'bxs-group', text: 'Team', path: '/admin/team' },
    // Settings and Logout are separate and can be handled differently if needed
  ]

  // Handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarHidden(!isSidebarHidden)
  }

  // Adjust sidebar based on window size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarHidden(true)
      } else {
        setIsSidebarHidden(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <section id='sidebar' className={isSidebarHidden ? 'hide' : ''}>
      <Link
        href='#'
        className='brand'
        style={{ textTransform: 'none', textDecoration: 'none' }}
      >
        <i className='bx bxs-smile'></i>
        <span className='text'>AdminHub</span>
      </Link>
      <ul className='side-menu top'>
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={activeIndex === index ? 'active' : ''}
            onClick={() => setActiveIndex(index)}
          >
            <Link
              to={item.path}
              style={{ textTransform: 'none', textDecoration: 'none' }}
            >
              <i className={`bx ${item.icon}`}></i>
              <span className='text'>{item.text}</span>
            </Link>
          </li>
        ))}
      </ul>
      <ul className='side-menu'>
        <li>
          <Link
            href='#'
            style={{ textTransform: 'none', textDecoration: 'none' }}
          >
            <i className='bx bxs-cog'></i>
            <span className='text'>Settings</span>
          </Link>
        </li>
        <li>
          <Link
            href='#'
            className='logout'
            style={{ textTransform: 'none', textDecoration: 'none' }}
            onClick={logoutSubmit}
          >
            <i className='bx bxs-log-out-circle'></i>
            <span className='text'>Logout</span>
          </Link>
        </li>
      </ul>
    </section>
  )
}

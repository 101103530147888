import FirstImageNews from '../../../assets/img/fcdc269b-7a74-fa25-0f0f-0917872447f4-768x257.png'
import ImageDetailsNews from '../../../assets/img/3e02f29a-2a8a-d096-2132-50ec9ec066ca.jpg'
import imageNews from '../../../assets/img/a6c06b9b-bc01-4537-d8ed-8ad4c887371c.png'
export default function EightNewsDetails() {
  return (
    <>
      <section
        className='d-flex firstSection justify-content-center align-items-center'
        style={{
          height: '50vh',
          width: '100vw',
          backgroundImage: `url(${FirstImageNews})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></section>
      <section
        style={{ height: '200vh' }}
        className='d-flex firstSection justify-content-center pt-0'
      >
        <article
          className='pb-3 pt-4'
          style={{ width: '50vw', paddingLeft: '0' }}
        >
          <div
            style={{
              borderBottom: '1px solid #c4c4c4',
              width: '50vw',
              height: '50vh',
              paddingBottom: '20px',
            }}
          >
            <p
              style={{
                color: '#EA4D2A',
                padding: '0',
                margin: '0',
              }}
            >
              28 May 2022
            </p>
            <h1>PRODUCT OF THE YEAR 2022</h1>
            <img
              src={ImageDetailsNews}
              alt='sectiom Image'
              className='imageNews'
            />
            <div
              style={{
                width: '50vw',
                height: '50vh',
              }}
              className='d-flex align-items-center justify-content-center'
            >
              <img src={imageNews} alt='sectiom Image' />
            </div>
            <p
              style={{
                padding: '10px 0',
                margin: '0',
                textTransform: 'none',
              }}
            >
              The Avion Full Flight Simulator is designed, developed and built
              in The Netherlands. Avion is a Dutch company with European
              partners. Just like the nature of our country, we are independent
              and innovative, this is called Dutch Design. “Therefore we use
              Glow Mobile Box to present ourself at shows and events” Wouter
              Hollenga, Sales & Marketing Officer at Avion Group.
            </p>
            <img
              src={FirstImageNews}
              alt='sectiom Image'
              className='imageNews pt-3 mb-3'
            />
            <a
              href='/news'
              style={{
                textTransform: 'none',
                textDecoration: 'none',
                width: '8vw',
                height: '4vh',
                border: '1px solid #b8b8b8',
                borderRadius: '5px',
                color: '#b8b8b8',
              }}
              className='p-1'
            >
              Return to archive
            </a>
          </div>
        </article>
      </section>
    </>
  )
}
